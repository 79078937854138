import View from '../View'

import Scaled from '../Scaled'
import { StyleSheet, css } from 'aphrodite';

import { Container } from './styles'
import { useCallback, useEffect, useState } from 'react';
import { useSockets } from '../../context/socket.context';
import { Howl } from 'howler';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function CrossingTheRiver() {  
  
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [topNo, setTopNo ] = useState({no: 12.6, dist: 5.4});
  const [topRobot, setTopRobot ] = useState({blue: 0, green: 0, red: 0, yellow: 0});

  const [ topCabeca, setTopCabeca ] = useState(10.8);
  const { socket } = useSockets();
  const [ robot, setRobots ] = useState({blue: false, green: false, red: false, yellow: false})
  const [ pontos, setPontos ] = useState({blue: 0, green: 0, red: 0, yellow: 0, point: ""})
  const [ garraPosition, setGarraPosition ] = useState({green: 1, blue: 20, red: 41, yellow: 63})

  const [ showGarra, setShowGarra ] = useState([false,false,false,false])
  const [ imgRobots, setImgRobots ] = useState(["/assets/images/rio/roboverde.png","/assets/images/rio/roboazul.png","/assets/images/rio/roboamarelo.png","/assets/images/rio/robovermelho.png"])

  const [musicPonto] = useState<Howl>(new Howl({
    src: ['/assets/music/pontuacao.mp3'],
    autoplay: false,
    loop: false,
    volume: sessionStorage.getItem("musica") ? Number(sessionStorage.getItem("musica")?.toString()) / 50 : 1
    
  }));

  const [natureza] = useState<Howl>(new Howl({
    src: ['/assets/music/natureza.mp3'],
    autoplay: false,
    loop: false,
    volume: sessionStorage.getItem("musica") ? Number(sessionStorage.getItem("musica")?.toString()) / 100 : 1
    
  }));

  const [cachoeira] = useState<Howl>(new Howl({
    src: ['/assets/music/cachoeira.mp3'],
    autoplay: false,
    loop: true,
    volume: sessionStorage.getItem("musica") ? Number(sessionStorage.getItem("musica")?.toString()) / 100 : 1
    
  }));
  const [passoRobo] = useState<Howl>(new Howl({
    src: ['/assets/music/passorobo.mp3'],
    autoplay: false,
    loop: false,
    volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1
    
  }));


  useEffect(() => {

    if(windowDimensions.width > 2400) {
      const topTemp = {no: 8, dist: 4.3};
      setTopNo(topTemp);
    }
    
    if(windowDimensions.height < 420) {
      const topTemp = {no: 15.3, dist: 5.4};
      setTopNo(topTemp);
    } 
    musicPonto.play();
    natureza.play();
    cachoeira.play();
    const roomId = sessionStorage.getItem("nameRoom");

    socket.on("list-rooms-name", (data : [any]) => {      
      const filtro = data.find(x => x.NameRoom === roomId);         
      setRobots({blue: filtro.TeamBlueName.length > 0, green: filtro.TeamGreenName.length > 0, yellow:filtro.TeamOrangeName.length > 0, red: filtro.TeamRedName.length > 0 })            
    });


    socket.emit("list-rooms-name");
  
    const pontos = JSON.parse(sessionStorage.getItem("point")!.toString());
    setPontos(pontos);
    const times = [ pontos.point.time.indexOf("green") > -1, 
      pontos.point.time.indexOf("blue") > -1,
      pontos.point.time.indexOf("yellow") > -1,
      pontos.point.time.indexOf("red") > -1
    ]; 
    setShowGarra(times);  
    let imgRobotTemp = ["/assets/images/rio/roboverde.png","/assets/images/rio/roboazul.png","/assets/images/rio/roboamarelo.png","/assets/images/rio/robovermelho.png"];
    if(times[0]) imgRobotTemp[0] = "/assets/images/rio/passoverde.png";    
    if(times[2]) imgRobotTemp[2] = "/assets/images/rio/passoamarelo.png";
    if(times[3]) imgRobotTemp[3] = "/assets/images/rio/passovermelho.png";    
    setImgRobots(imgRobotTemp);

    setTimeout(() => {
      imgRobotTemp = ["/assets/images/rio/roboverde.png","/assets/images/rio/roboazul.png","/assets/images/rio/roboamarelo.png","/assets/images/rio/robovermelho.png"];
      setImgRobots(imgRobotTemp);
    }, 3000);
    if(times.indexOf(true) > -1)  passoRobo.play();
    const posicaoGarras = {green: 1, blue: 20, red: 41, yellow: 63}
    if (pontos.green >= 10) posicaoGarras.green += 6;
    if (pontos.blue >= 10) posicaoGarras.blue += 6;
    if (pontos.red >= 10) posicaoGarras.red += 6;
    if (pontos.yellow >= 10) posicaoGarras.yellow += 6;

    if (pontos.green >= 20) posicaoGarras.green += 6;
    if (pontos.blue >= 20) posicaoGarras.blue += 6;
    if (pontos.red >= 20) posicaoGarras.red += 6;
    if (pontos.yellow >= 20) posicaoGarras.yellow += 6;
    
    setGarraPosition(posicaoGarras);   

    let tempTopRobot = {blue:  (topCabeca * pontos?.blue), green:  (topCabeca * pontos?.green), red:  (topCabeca * pontos?.red), yellow:  (topCabeca * pontos?.yellow)};
    if(windowDimensions.height < 420) {     
      tempTopRobot = {blue: tempTopRobot.blue + 10, green:  tempTopRobot.green + 10, red:  tempTopRobot.red + 10, yellow:  tempTopRobot.yellow + 10};
    }
    setTopRobot(tempTopRobot);

    
    return function limpar () {      
      passoRobo.stop();
      musicPonto.stop();
      natureza.stop();
      cachoeira.stop();
    };
    
  },[]);

const getCssBox = useCallback((topo) => {
  let topoTemp = 5.5;
  if(windowDimensions.width > 2400) {
    topoTemp = 3.2;
    topo = topo -3;
  } 
  
  if(windowDimensions.height < 420) {

    topo = topo + 2.7;
  } 

      const y = topo;      
      const translateKeyframes  = {
              '0%' : {  top: topo + "vw"},
              '100%' : {  top: topo + topoTemp + "vw" }
            };

      const styles = StyleSheet.create({
        boxGarra: {
          animationName: [translateKeyframes],
          animationDuration: '4s',          
          animationFillMode: "forwards",
        },
      });

      return css(styles.boxGarra);

  },[])
  

  return (
    <>
      <Container>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-1" style={{ width: "10%" }}>
          </div>

          <div className="col-10" style={{ width: "80%",   overflow: "hidden" }}>

          <img src="/assets/images/rio/bandeira.png" alt="" className='bandeira' />
            <img src="/assets/images/rio/bandeira.png" alt="" className='bandeira2' />
            <img src="/assets/images/rio.png" alt="" className='rio' />
            <img src="/assets/images/rio.jpg" alt="" className='congratulation-rio' />

    
              
              {[...Array(5)].map((x, i) =>
                   robot.green &&  <img src="/assets/images/no.png" alt="" className='no' style={{left: "8.7vw", top: (i * topNo.dist) + topNo.no  + "vw"}}  key={i} />       
              )}
      
              {[...Array(5)].map((x, i) =>
                 robot.blue &&    <img src="/assets/images/no.png" alt="" className='no' style={{left: "27.6vw", top: (i * topNo.dist) + topNo.no + "vw"}}  key={i} />       
              )}

              {[...Array(5)].map((x, i) =>
                   robot.yellow &&  <img src="/assets/images/no.png" alt="" className='no' style={{left: "47.6vw", top: (i * topNo.dist) + topNo.no + "vw"}}  key={i} />       
              )}

              {[...Array(5)].map((x, i) =>
                  robot.red &&   <img src="/assets/images/no.png" alt="" className='no' style={{left: "67.6vw", top: (i * topNo.dist ) + topNo.no + "vw"}}  key={i} />       
              )}                 

              {robot.green &&  <img src="/assets/images/corda.png" alt="" className='corda' style={{left: "8vw"}}  /> }
              {robot.blue && <img src="/assets/images/corda.png" alt="" className='corda' style={{left: "27vw"}}  />}
              {robot.yellow &&  <img src="/assets/images/corda.png" alt="" className='corda' style={{left: "47vw"}}  />}
              {robot.red && <img src="/assets/images/corda.png" alt="" className='corda' style={{left: "67vw"}}  /> }        

            {robot.green && <img src={imgRobots[0]} alt="" className={`img-fluid box-img1 robo-rio ${ showGarra[0] && getCssBox(topNo.dist * pontos.green)}`}  style={{left: "4.4vw", top: topRobot.green + "vh"}} /> }
            {(robot.blue && pontos) && <img src={imgRobots[1]} alt="" className={`img-fluid box-img2 robo-rio ${ showGarra[1] && getCssBox(topNo.dist * pontos.blue)}`}  style={{left: "23.5vw", top: topRobot.blue + "vh"}}  />}
            {robot.yellow && <img src={imgRobots[2]} alt="" className={`img-fluid box-img3 robo-rio ${ showGarra[2] && getCssBox(topNo.dist * pontos.yellow)}`}  style={{left: "43.5vw", top: topRobot.yellow + "vh"}}  />}
            {robot.red && <img src={imgRobots[3]} alt="" className={`img-fluid box-img4 robo-rio ${ showGarra[3] && getCssBox(topNo.dist * pontos.red)}`}  style={{left: "63.5vw", top: topRobot.red + "vh"}}  /> }


          </div>
          <div className="col-1" style={{ width: "10%" }}>

          </div>
        </div>

      </Container>
    </>
  )
}
