import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  Dispatch,
} from 'react'

import { api } from '../services/api'


interface IPlayerProviderProps {
  children: React.ReactNode
}

interface IPlayerContextData {
  room: string | null
  setRoom: Dispatch<string>
  handleSessionCreation: (name: string) => Promise<void>
}

interface ISession {
  token: string
  refresh_token: string
}

const PlayerContext = createContext({} as IPlayerContextData)

export function PlayerProvider({ children }: IPlayerProviderProps) {
  
  const [room, setRoom] = useState<string | null>(null)
  
  const handleSessionCreation = useCallback(async (name: string) => {
    await api.post<ISession>('/sessions', { name }).then(response => {
      const { token, refresh_token } = response.data

      localStorage.setItem('token', token)
      localStorage.setItem('refresh_token', refresh_token)
    })
  }, [])

  return (
    <PlayerContext.Provider value={{ room, setRoom, handleSessionCreation }}>
      {children}
    </PlayerContext.Provider>
  )
}

export function usePlayer(): IPlayerContextData {
  const context = useContext(PlayerContext)

  if (!context) {
    throw new Error('usePlayer must be used within an PlayerProvider.')
  }

  return context
}
