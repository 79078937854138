import { useEffect, useCallback, useState } from 'react'
import { Howl } from 'howler'
import gsap, { Back } from 'gsap'

import { useGameplay } from '../../../hook/useGameplay'

import { Container, Picture, Content, Badge, Sound } from './styles'
import { useSockets } from '../../../context/socket.context'

interface IOptionProps {
  color: 'blue' | 'yellow' | 'green' | 'red' | 'gray'
  letter: 'A' | 'B' | 'C' | 'D'
  label?: string
  image?: string
  sound_en?: string
  sound_pt?: string
  grande?: boolean
  game?:string
  lang: string
  colorRobot: string
  
}


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


export default function Option({
  color,
  letter,
  label,
  image,
  sound_en,
  sound_pt,
  grande,
  lang,
  colorRobot,
  game
}: IOptionProps) {

  const [sound, setSound] = useState<null | Howl>()
  const [first, setFirst] = useState(true);
  const [number, setNumber] = useState(0);
  const [disable, setDisable] = useState(false);
  const [gray, setGray] = useState(false);

  const [disableTeacher, setDisableTeacher] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [roletaEffect, setRoletaEffect] = useState(false);
  const [roletaPower, SetRoletaPower] = useState(0);
  const [votesQtd, setvotesQtd] = useState({ yellow: 0, green: 0, red: 0, blue: 0 });

  const [icon, setIcon] = useState("");
  const [valor, setValor] = useState(false);

  const { votes, correctAnswer } = useGameplay()

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const { socket } = useSockets();

  useEffect(() => {



    setFirst(true);
    socket.on("vote", (body) => {

      const votos = {
        green: 0,
        yellow: 0,
        red: 0,
        blue: 0
      }
      const cor = sessionStorage.getItem("color");
      if (cor === "green") {
        setNumber(body.votesGreen.filter((y: any) => y.vote === letter).length);
      } else if (cor === "red") {
        setNumber(body.votesRed.filter((y: any) => y.vote === letter).length);
      } else if (cor === "yellow") {
        setNumber(body.votesYellow.filter((y: any) => y.vote === letter).length);
      } else if (cor === "blue") {
        setNumber(body.votesBlue.filter((y: any) => y.vote === letter).length);
      }

      votos.green = body.votesGreen.filter((y: any) => y.vote === letter).length;
      votos.blue = body.votesBlue.filter((y: any) => y.vote === letter).length;
      votos.red = body.votesRed.filter((y: any) => y.vote === letter).length;
      votos.yellow = body.votesYellow.filter((y: any) => y.vote === letter).length;
      setvotesQtd(votos);

    });

    if(sessionStorage.getItem("TeamFreezed") === sessionStorage.getItem("color")) {
      const body = {
        vote: "E",
        room: sessionStorage.getItem("nameRoom"),
        color: colorRobot,
        nameVote: sessionStorage.getItem("name")
      }
      socket.emit("emit-votes", body);
    } 

  }, [])

  useEffect(() => {

    if (sessionStorage.getItem("roleta")) {

      if(sessionStorage.getItem("color") !== "teacher") {        
        const roleta = JSON.parse(sessionStorage.getItem("roleta")!.toString());      
      const index = roleta.findIndex((o:any) => { return o.team === sessionStorage.getItem("color") } );
      const timeRoleta = index  > -1;

      // const roleta = JSON.parse(sessionStorage.getItem("roleta")!.toString());
      // const colorRoleta = sessionStorage.getItem("color");
     // setIcon("/assets/images/roleta/block_red.png");
      if (timeRoleta && roleta[index].power === 1 && color === "yellow") {
        setDisable(true);
        color = "gray";
        setIcon("/assets/images/roleta/block_yellow.png");
      } else if (timeRoleta && roleta[index].power === 4 && color === "red") {
        setDisable(true);
        color = "gray";
        setIcon("/assets/images/roleta/block_red.png");
      } else if (timeRoleta && roleta[index].power === 6 && color === "green") {
        setDisable(true);
        color = "gray";
        setIcon("/assets/images/roleta/block_green.png");
      } else if (timeRoleta && roleta[index].power === 8) {
        SetRoletaPower(8);        
        setDisable(sessionStorage.getItem("correct_answer")!.toString() !== letter);
        setRoletaEffect(true);
        setDisableTeacher(true);
        setIcon("/assets/images/roleta/teacher.png");
      } else if (timeRoleta && roleta[index].power === 2) {
        setHidden(true);
      } else if (timeRoleta && roleta[index].power === 5) {
        SetRoletaPower(5);
        setRoletaEffect(true);
      }
      }
      
    }
 
  }, [color, roletaEffect])

  useEffect(() => {

    if (sound_en !== "" && lang === "ingles") {
      setSound(() => {
        return new Howl({
          src: ['https://frontgametime.internationalschool.global' + sound_en!],
          volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1,
          html5: true,
        })
      })
    } else if (sound_pt !== "" && lang === "portugues") {
      setSound(() => {
        return new Howl({
          src: ['https://frontgametime.internationalschool.global' + sound_pt!],
          volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1,
          html5: true,
        })
      })
    } else {
      setSound(null);
    }
  }, [sound_en, sound_pt, lang]);


  const handleChangeChk = useCallback((e) => {
    const body = {
      vote: letter,
      room: sessionStorage.getItem("nameRoom"),
      color: colorRobot,
      nameVote: sessionStorage.getItem("name")
    }
    if(e.target.checked  && letter !== sessionStorage.getItem("correct_answer")!.toString()) {
      setValor(true);
    }   

    socket.emit("emit-votes", body);

  }, [valor, letter, number, colorRobot])

  useEffect(() => {

    setTimeout(() => {
      if (first) {
        setFirst(false);
        return;
      }
      if (correctAnswer && correctAnswer !== "") {
  
        if (correctAnswer === letter) {
          gsap
            .timeline({ delay: 2 })
            .fromTo(
              `#checked${letter}`,
              { autoAlpha: 0, scale: 2 },
              { autoAlpha: 1, scale: 1, ease: Back.easeOut.config(1.2) }
            )
  
          setTimeout(() => {
            gsap
              .timeline({ delay: 2 })
              .fromTo(
                `#checked${letter}`,
                { autoAlpha: 1, scale: 1 },
                { autoAlpha: 0, scale: 2, ease: Back.easeOut.config(0) }
              )
          }, 2000);
  
          return
        } else if(valor) {
          gsap
          .timeline({ delay: 2 })
          .fromTo(
            `#checked-erro${letter}`,
            { autoAlpha: 0, scale: 2 },
            { autoAlpha: 1, scale: 1, ease: Back.easeOut.config(1.2) }
          )
  
        setTimeout(() => {
          gsap
            .timeline({ delay: 2 })
            .fromTo(
              `#checked-erro${letter}`,
              { autoAlpha: 1, scale: 1 },
              { autoAlpha: 0, scale: 2, ease: Back.easeOut.config(0) }
            )
           }, 2000);
        }
        
        gsap
          .timeline({ delay: 2 })
          .fromTo(
            `#option${letter}`,
            { opacity: 1.0 },         
            { css: {'filter': 'grayscale(100%)'  } , ease: Back.easeOut.config(1.2) }
          )
          
  
        setTimeout(() => {
          gsap
            .timeline({ delay: 2 })
            .fromTo(
              `#option${letter}`,
              { opacity: 0.5 },
              { opacity: 1.0, ease: Back.easeOut.config(1.2) }
            )
  
          const elements = document.getElementsByName('checkcad');
          Array.prototype.forEach.call(elements, function (element) {
            element.checked = false;
          });
  
        }, 2000);
  
      }
    }, 1000);
 

  }, [correctAnswer, number, disable, hidden])

  return (
    <Container
      id={`option${letter}`}
      style={{paddingTop: windowDimensions.height > 420 && game === "missing" ? "15px" : "0px" }}
      className={`${game === "missing"   && windowDimensions.height <= 420 ? " top-32vh " : " container-missing "}
      
      checkcad ${image && game === "missing" && " heigth-150 "  && windowDimensions.height < 420 && 'container-respostas' } ${image && windowDimensions.height < 420 && 'container-respostas' } container-checkcad ${game === 'missing' && "img-missing"} w-100 checkcad-border-selected checkcad-${(!disable && !hidden) ? color : "gray"} base-class `}
    >      
      <input
        type="radio"
        className="checkcad-input w-100"
        name="checkcad"
        onChange={event => handleChangeChk(event)}
        disabled={disable || sessionStorage.getItem("color") === "teacher"}
        id={`checkedInput${letter}`}
      />
      <span className={`w-100 checkcad-label ${game === "magic" && " checklabel-magic "} ${grande && "option-big"}`} style={disable ? { cursor: "default" } : { cursor: "pointer" }} >
        <span className="checkcad-checked" id={`checked${letter}`}></span>
        {valor === true && (<span className="checkcad-checked-errado" id={`checked-erro${letter}`}></span>)} 
    
        {(sound && !hidden) && <Sound onClick={() => sound.play()} />}

        {image && (
          <>
            {!hidden && <Picture className={`checkcad-image ${game === "missing" && "img-missing"}`} > <img src={`https://frontgametime.internationalschool.global${image}`} />  </Picture>}
            {hidden && "???"} 
            {(!label) && <span>
              
              {(!disable && sessionStorage.getItem("color") !== "teacher" && !roletaEffect) && <span style={{ float: "right" }} className='font-voto' > {number} </span>}
              {(colorRobot === "head_green.png" && !disable && !disableTeacher && !roletaEffect) && (<img className='cabeca-voto' style={{ float: "right" }} src='/assets/static/robot/head_green.png' alt="" />)}
              {(colorRobot === "head_red.png" && !disable && !disableTeacher && !roletaEffect) && (<img className='cabeca-voto' style={{ float: "right" }} src='/assets/static/robot/head_red.png' alt="" />)}
              {(colorRobot === "head_yellow.png" && !disable && !disableTeacher && !roletaEffect) && (<img className='cabeca-voto' style={{ float: "right" }} src='/assets/static/robot/head_yellow.png' alt="" />)}
              {(colorRobot === "head_blue.png" && !disable && !disableTeacher && !roletaEffect) && (<img className='cabeca-voto' style={{ float: "right" }} src='/assets/static/robot/head_blue.png' alt="" />)}
              {(disable && sessionStorage.getItem("color") !== "teacher" && !label && !roletaEffect) && (<span className="heading-4 text-left m-0 "> <img className='cabeca-voto' src={icon} alt="" /> </span>)}  
            </span>}
            
            {(!disable  && !label && roletaPower === 8) && (<span className="heading-4 text-left m-0" style={{padding: "0px 0px 5px 5px", }}> <img className='cabeca-voto' src={icon} alt="" /> </span>)}
          </>
        )}

        {(label) && <span className={`heading-4 text-left m-0 label-respostas ${ (roletaEffect || sessionStorage.getItem("color") === "teacher")  ? " label-respostas-magic-0 " : " label-respostas-magic-30 " } `} style={{padding: "0px !important"}} >
              <div className="row" style={{padding: "0px !important", display:"flex",   alignItems: "center", justifyContent: "center"}} >
                <div className={`col-1${game === "missing" ? "0" : "0"} ${grande && !image && "label-option-big"} ${game === "magic" && " label-magic "}
                ${!image && label ? " img-label-control " : " no-img-label-control "}
                `}  
                style={{minHeight: (windowDimensions.width <= 1800 && windowDimensions.height > 425) && (game === "magic" || !image) ? "35px" :  windowDimensions.height < 425 ? "35px" : "0px",
                 paddingTop: (roletaEffect || sessionStorage.getItem("color") === "teacher") ? "0px" : "5px", paddingLeft: (!image && label) ? "15px" : "10px", display:"flex",  alignItems: " center", justifyContent: "start"}}>
                {(hidden && !image) && "???"} 
                {(!hidden) && label} 
                </div>
                <div className="col-2">
                <div style={{ float: "right", display:"flex",   alignItems: "center", justifyContent: "center"}} >
             
             {(colorRobot === "head_green.png" && !disable && !disableTeacher && !roletaEffect) && (<img className='cabeca-voto' src='/assets/static/robot/head_green.png' alt="" style={{marginTop: "0px"}}/>)}
             {(colorRobot === "head_red.png" && !disable && !disableTeacher && !roletaEffect) && (<img className='cabeca-voto' src='/assets/static/robot/head_red.png' alt=""  style={{marginTop: "0px"}}/>)}
             {(colorRobot === "head_yellow.png" && !disable && !disableTeacher && !roletaEffect) && (<img className='cabeca-voto' src='/assets/static/robot/head_yellow.png' alt="" style={{marginTop: "0px"}}/>)}
             {(colorRobot === "head_blue.png" && !disable && !disableTeacher && !roletaEffect) && (<img className='cabeca-voto'  src='/assets/static/robot/head_blue.png' alt=""  style={{marginTop: "0px"}} />)}
          
             {(!disable && !disableTeacher && !roletaEffect && sessionStorage.getItem("color") !== "teacher") && (<div > <span > {number} </span>  </div>)}
             {(disable && !disableTeacher && label && !roletaEffect) && (<span className="heading-4 text-left m-0 label-respostas"> <img className='cabeca-voto' src={icon} alt="" /> </span>)}    
             {(!disable  && label && roletaPower === 8) && (<img className='cabeca-voto' src={icon} alt="" style={{marginTop: "0px"}}/> )}
             </div>  
                </div>
              </div>
         
              
            </span>}

        


        {(sessionStorage.getItem("color") === "teacher" || roletaPower === 5) && (
          <div className='d-flex show-votes w-100' style={{padding: "0px !important"}} >
         <div className='center-flex'>
          <img className='cabeca-voto' src='/assets/static/robot/head_green.png' alt="" /> <span style={{marginLeft:"2px", justifyContent: "flex-start" }} className='font-voto'>  {votesQtd.green} </span>
         </div>
         <div className='center-flex'>
          <img className='cabeca-voto' src='/assets/static/robot/head_red.png' alt="" />  <span style={{marginLeft:"2px"}} className='font-voto'> {votesQtd.red} </span>
         </div>
         <div className='center-flex'>
          <img className='cabeca-voto' src='/assets/static/robot/head_yellow.png' alt="" />  <span style={{marginLeft:"2px"}} className='font-voto'> {votesQtd.yellow} </span>
         </div>
         <div className='center-flex'>
          <img className='cabeca-voto' src='/assets/static/robot/head_blue.png' alt="" /> <span style={{marginLeft:"2px" }} className='font-voto' > {votesQtd.blue} </span>
         </div>
          </div>
        )}
      </span>
    </Container>
  )
}
