import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { io } from 'socket.io-client'
import { useSockets } from '../../context/socket.context'

import { Container } from './styles'

interface ICardRoomProps {
  match: string
  name: string
}

export default function CardRoom({ match, name }: ICardRoomProps) {
  const router = useHistory()
  const { socket } = useSockets();

  const handleClick = useCallback(() => {   

    const body = {
      name:  sessionStorage.getItem("name"),
      nameRoom: name
    }
    sessionStorage.setItem("tipo-usuario", "aluno");    
    socket.emit("join-room", body);    
    router.replace(`/rooms/${match}`)
  }, [match,])
  return (
    <Container className="carton" onClick={handleClick}>
      <div className="carton-content">
        <h2 className="heading-4 carton-content-text">{name}</h2>
      </div>
    </Container>
  )
}
