import styled from 'styled-components'

import timer from '../../static/timer.png'
import timerArrow from '../../static/timer_arrow.png'

export const Container = styled.div`
  background-image: url(${timer});
  background-repeat: no-repeat;
  background-size: contain;
  width: 130px;
  height: 192px;
  position: absolute;
  z-index: 1;
`

export const Arrow = styled.div`
  background-image: url(${timerArrow});
  background-repeat: no-repeat;
  background-size: contain;
  width: 185px;
  height: 185px;
  position: absolute;
  left: -28px;
  transition: 0.3s;
  top: -17px;
  z-index: 1;
`

export const Text = styled.span`
  font-size: 22px;
  text-align: center;
  width: 100%;
  padding-bottom: 13px;
  position: absolute;
  bottom: 0;
`
