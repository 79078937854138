import React from 'react'

import Timer from '../../components/Timer'

import Teams from '../../components/Teams'
import Presentation from '../../components/Presentation'
import Quiz from '../../components/Quiz'
import MagicBox from '../../components/MagicBox'
import MissingObject from '../../components/MissingObject'
import Roulette from '../../components/Roulette'
import BoxGain from '../../components/BoxGain'
import CrossingTheRiver from '../../components/CrossingTheRiver'
import Congratulation from '../../components/Congratulation'

import { useGameplay } from '../../hook/useGameplay'
import { Container, Transition } from './styles'
import LobbyRooms from '../Rooms/Lobby'

const routes = {
  presentation: Presentation,
  quiz: Quiz,
  mistery_box: MagicBox,
  hidden_object: MissingObject,
  crossingTheRiver: CrossingTheRiver,
  // BoxGain,
  // Roulette,
}

export default function Gameplay() {
  const { route } = useGameplay()
  return (
    <></>
  )
}
