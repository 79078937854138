import View from '../View'
import { Container, } from './styles'
import React, { useCallback, useEffect, useState } from 'react';
import { Howl } from 'howler';


export default function PresentationMissingObject() {

  const [lang, setLang] = useState("ingles");
  const [sound] = useState<Howl>(new Howl({
    src: ["/assets/music/quiz/musica.mp3"],
    volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1,
    autoplay: false
  }))
  const [english] = useState<Howl>(new Howl({
    src: ["/assets/music/missing/missingobject.mp3"],
    volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1,
    autoplay: false
  }))

  const [portugues] = useState<Howl>(new Howl({
    src: ["/assets/music/missing/objeto.mp3"],
    volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1,
    autoplay: false
  }))

  const changeLanguage = useCallback(() => {
    if (lang === "ingles") setLang("portugues"); else setLang("ingles")
  },
    [lang]
  )

  useEffect(() => {
    sound.play();
    return function limpar () {      
      sound.stop();
    };
  }, [])

  const playQuestion = useCallback(() => {
    if (lang === "ingles") {
      english.play();
    } else {
      portugues.play();
    }
  },
    [lang]
  )

  return (
    <>
      <div className="row " style={{ maxWidth: "98%", height: "100%" }}>
        <div className="group-robot col-12 center-flex" style={{ width: "100%" }}>


        </div>
        <div className="col-1" style={{ width: "15%" }} >
          <div className='center-flex'>
            {(lang === "ingles") ? <img src='/assets/static/icons/ingles.png' alt="" className='changeLanguage center-flex' onClick={changeLanguage} /> :
              <img src='/assets/static/icons/portugues.png' alt="" className='changeLanguage center-flex' onClick={changeLanguage} />
            }</div>
        </div>

        <div className="col-10" style={{ width: "70%" }} >

          <Container className="main container center-flex" style={{ display: "flex", flexDirection: "column" }} >
            <div>
              <div className='text-center' style={{ display: "flex", flexDirection: "column", height: '100%' }} >
                <div className='group-heading-presentation'>
                  <img src="/assets/images/missingobject.png" alt="" className='heading-presentation'  />
                  <img src="/assets/static/sound.png" alt="" className='sound-heading sound-missing' onClick={playQuestion} style={{left: "18%"}} />
                </div>

                <div className='text-presentation'>
                  {lang !== "ingles" && <span> O jogador deverá prestar atenção na cena mostrada na tela, o robô apagará a luz e um objeto desaparecerá. O jogador observará a imagem e deverá descobrir qual é o objeto que sumiu. Clicando em um dos botões coloridos, o jogador poderá dizer qual objeto está faltando na cena. </span>}
                  {lang === "ingles" && <span> In this game, the player must pay attention to the scene shown on the screen, the robot will turn off the light and an object will disappear. The player should look at the image and try to discover the missing object. By clicking on one of the colored buttons, the player willl say which object is missing in the scene. </span>}
                </div>
              </div>
            </div>

          </Container>
        </div>
        <div className="col-1" style={{ width: "15%" }} >

        </div>
      </div>
    </>
  )
}
