import View from '../View'

import Scaled from '../Scaled'
import { StyleSheet, css } from 'aphrodite';

import { Container } from './styles'
import { useCallback, useEffect, useState } from 'react';
import { useSockets } from '../../context/socket.context';
import { Howl } from 'howler';


export default function BoxGain() {  
  
  const { socket } = useSockets();
  const [ robot, setRobots ] = useState({blue: false, green: false, red: false, yellow: false})
  const [ pontos, setPontos ] = useState({blue: 0, green: 0, red: 0, yellow: 0, point: ""})
  const [ garraPosition, setGarraPosition ] = useState({green: 1, blue: 20, red: 41, yellow: 63})
  const [ urlGarra, setUrlGarra ] = useState(`${"/assets/images/garra.png"}?${global.Date.now()}`)

  const [ showGarra, setShowGarra ] = useState([true,true,true,true])

  const [soundGarra] = useState<Howl>(new Howl({
    src: ['/assets/music/articulacao.mp3'],
    autoplay: false,
    loop: false,
    volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1
    
  }));

  const [musicPonto] = useState<Howl>(new Howl({
    src: ['/assets/music/pontuacao.mp3'],
    autoplay: false,
    loop: false,
    volume: sessionStorage.getItem("musica") ? Number(sessionStorage.getItem("musica")?.toString()) / 100 : 1
    
  }));

  useEffect(() => {
    
    musicPonto.play();
    const roomId = sessionStorage.getItem("nameRoom");

    socket.on("list-rooms-name", (data : [any]) => {      
      const filtro = data.find(x => x.NameRoom === roomId);         
      setRobots({blue: filtro.TeamBlueName.length > 0, green: filtro.TeamGreenName.length > 0, yellow:filtro.TeamOrangeName.length > 0, red: filtro.TeamRedName.length > 0 })       
   
    });
    socket.emit("list-rooms-name");
    const pontos = JSON.parse(sessionStorage.getItem("point")!.toString());
    setPontos(pontos);
    const times = [ pontos.point.time.indexOf("green") > -1, 
      pontos.point.time.indexOf("blue") > -1,
      pontos.point.time.indexOf("yellow") > -1,
      pontos.point.time.indexOf("red") > -1
    ];
    setShowGarra(times);    
    if(times.indexOf(true) > -1)  soundGarra.play();

    const posicaoGarras = {green: 1, blue: 20, red: 63, yellow: 41}
    if (pontos.green >= 10) posicaoGarras.green += 6;
    if (pontos.blue >= 10) posicaoGarras.blue += 6;
    if (pontos.red >= 10) posicaoGarras.red += 6;
    if (pontos.yellow >= 10) posicaoGarras.yellow += 6;

    if (pontos.green >= 20) posicaoGarras.green += 6;
    if (pontos.blue >= 20) posicaoGarras.blue += 6;
    if (pontos.red >= 20) posicaoGarras.red += 6;
    if (pontos.yellow >= 20) posicaoGarras.yellow += 6;
    
    setGarraPosition(posicaoGarras);
    
    return function limpar () {      
      soundGarra.stop();
      musicPonto.stop();
    };
    
  },[]);

const getCssBox = useCallback((time, point) => {
      
      let ml = 0;
      if(time === "green") ml = 0;
      if(time === "blue") ml = 19; // 19
      if(time === "yellow") ml = 40;
      if(time === "red") ml = 62;

      let n = point;            

      if(point > 9) {
        ml += 6;
        n -= 10;
      } else if(point > 19) {
        ml += 12;
        n -= 20;
      }

      const y = (((n * 3.7) * -1) + 40);      

      const translateKeyframes  = {
              '0%' : {  margin: '-10% ' + ml + "%"  },
              '14%' : { margin: '5% ' + ml + "%"  },
              '30%' : { margin: '5% '+ ml + "%"  },
              '50%' : { margin: y + '% ' + ml + "%"  },
              '52%' : { margin: y - 2 + '% ' + ml + "%"  },
              '54%' : { margin: y + '% ' + ml + "%"  },
              '56%' : { margin: y - 1 + '% ' + ml + "%"  },
              '58%' : { margin: y + '% ' + ml + "%"  },
              '100%'  : { margin: y + '% ' + ml + "%"  },
            };

            const opacityKeyframes = {
              'from': {  margin: '-10% ' + ml + "%"  },
              'to': { margin: y + '% ' + ml + "%"  },
            };

      const styles = StyleSheet.create({
        boxGarra: {
          animationName: [opacityKeyframes, translateKeyframes],
          animationDuration: '4s',          
          animationFillMode: "forwards",
          position:'absolute',
          objectFit: 'cover',
          width: '5vw',        
          left: "8%"
        },
      });

      return css(styles.boxGarra);

  },[])

  
const getCssBoxFix = useCallback((number, time) => {
  let soma = 0;
  if(number > 9) {
    soma = 6;
    number -= 10;
  }
  if(number > 19) {
    soma = 12;
    number -= 20;
  }
  let ml = 0;
  if(time === "green") ml = 0;
  if(time === "blue") ml = 19; // 19
  if(time === "yellow") ml = 40;
  if(time === "red") ml = 62;
  ml += soma;
  const styles = StyleSheet.create({
    boxGarra: {         
      margin: (((number * 3.7) * -1) + 40).toString() + '%' + ml + '%',   
      position:'absolute',
      objectFit: 'cover',
      width: '5vw',        
      left: "8%"
    },
  });

  return css(styles.boxGarra);

},[])



  return (
    <>
      <Container>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-1" style={{ width: "10%" }}>
          </div>

          <div className="col-10" style={{ width: "80%",   overflow: "hidden" }}>

            <img src="/assets/images/box-gain.jpg" alt="" className='congratulation' />

              {Array(pontos.blue).fill(null).map((x, i) =>
                  <img src="/assets/images/box1.png" alt=""  className={getCssBoxFix(i, "blue")}  key={i}/>
              )}
              {Array(pontos.yellow).fill(null).map((x, i) =>
                  <img src="/assets/images/box1.png" alt=""  className={getCssBoxFix(i, "yellow")}  key={i}/>
              )}
              {Array(pontos.green).fill(null).map((x, i) =>
                  <img src="/assets/images/box1.png" alt=""  className={getCssBoxFix(i, "green")}  key={i}/>
              )}
              {Array(pontos.red).fill(null).map((x, i) =>
                  <img src="/assets/images/box1.png" alt=""  className={getCssBoxFix(i, "red")}  key={i}/>
              )}

            {showGarra[0] && <img src="/assets/images/box1.png" alt=""  className={getCssBox("green", pontos.green)} />}      
            {showGarra[1] && <img src="/assets/images/box1.png" alt=""  className={getCssBox("blue", pontos.blue)} />}                
            {showGarra[2] && <img src="/assets/images/box1.png" alt=""  className={getCssBox("yellow", pontos.yellow)} />}      
            {showGarra[3] && <img src="/assets/images/box1.png" alt=""  className={getCssBox("red", pontos.red)} />}      

            {robot.green && <img src="/assets/images/congratulation/gray.png" alt="" className='img-fluid box-img1' /> }
            {robot.blue && <img src="/assets/images/congratulation/blue.png" alt="" className='img-fluid box-img2' />}
            {robot.yellow && <img src="/assets/images/congratulation/yellow.png" alt="" className='img-fluid box-img3' />}
            {robot.red && <img src="/assets/images/congratulation/red.png" alt="" className='img-fluid box-img4' /> }
            {/* verde = 1, azul = 20, yellow = 41, red = 63 */}
         
            {showGarra[0] && <img src={`${sessionStorage.getItem("urlGarra1")}`}  alt="" className='img-fluid garra' style={{ left: garraPosition.green + "%",}} /> }
            {showGarra[1] && <img src={`${sessionStorage.getItem("urlGarra2") }`} alt="" className='img-fluid garra' style={{ left: garraPosition.blue + "%"}} /> }
            {showGarra[2] && <img src={`${sessionStorage.getItem("urlGarra3") }`} alt="" className='img-fluid garra' style={{ left: garraPosition.yellow + "%"}} /> }
            {showGarra[3] && <img src={`${sessionStorage.getItem("urlGarra4") }`} alt="" className='img-fluid garra' style={{ left: garraPosition.red + "%"}} /> }
            

  

          </div>
          <div className="col-1" style={{ width: "10%" }}>

          </div>
        </div>

      </Container>
    </>
  )
}
