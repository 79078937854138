import { useEffect, useState } from 'react'
import { Link, Redirect, useHistory, useParams, HashRouter } from 'react-router-dom'

import { Container, Picture } from './styles'

import { usePlayer } from '../../hook/usePlayer'

import { useForm } from "react-hook-form";
import Resizing from '../../templates/Resizing'
import { useSockets } from '../../context/socket.context';
import { Howl } from 'howler'



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}



export default function Main() {
  
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const { roomId } = useParams<{ roomId: string }>()
  const { handleSessionCreation } = usePlayer()
  const { socket, rooms } = useSockets();
  

  const [name, setName] = useState('')
  const [validName, setValidName] = useState(true);
  const [sound, setSound ] = useState<Howl>(new Howl({
    src: ['/assets/music/abertura.mp3'],
    autoplay: false,
    loop: true,
    volume:  sessionStorage.getItem("musica") ? Number(sessionStorage.getItem("musica")?.toString()) / 100 : 1
}));


  
  const validForm = () => {
    (name.length > 0) ? setValidName(true) : setValidName(false);
  };

  useEffect(() => {
    sound.play();
  }, [])

  useEffect(() => { 
    return function limpar () {      
      sound.stop();
    };
  }, []);
  

  const history = useHistory();

  function access() {
    
    if (name.length > 0) {
      sessionStorage.setItem("name", name);
      sound.stop();
      history.push("/rooms/create");
    } else {
      setValidName(false);
    }
  }  
  function play() {
    
    const videoElement = document.getElementById("root");

    
    if (name.length > 0) {      
      sound?.stop();
      sessionStorage.setItem("name", name);
      sessionStorage.removeItem("roleta");
      sessionStorage.setItem("correct_answer", "");

    if(roomId !== undefined) {
      const body = {
        name:  sessionStorage.getItem("name"),
        nameRoom: roomId
      }

      sessionStorage.setItem("tipo-usuario", "aluno");    
      socket.emit("join-room", body);    
      history.push(`/rooms/${roomId}`)
    } else {
      history.push("/rooms");
    }
    } else {
      setValidName(false);
    } 
  }

  function configRoute() {
    history.push("/config");
  }

  return (
 
        <div className="container-main">
          <div className="row">
            <div className="col-6 text-start">
              <a style={{maxWidth: "300px"}} target='_blank' href='https://internationalschool.global/'>
                <img src="/assets/images/main/internationalschool.svg" alt="" className='img-responsive bouce-in-animation' style={{maxWidth: "300px"}}  /> 
              </a>
            </div>
            <div className="col-6 text-end">
                <img src="/assets/static/buttons/btn_config.png" alt="" className='img-responsive bouce-in-animation ' style={{maxWidth: "100px", cursor:"pointer"}} onClick={configRoute} />              
            </div>
            <div className='col-12 text-end manual-container'>
              <a style={{maxWidth: "100px"}} target='_blank' href='https://is.tec.br/#'>
                <img src="/assets/images/buttons/manual.png" alt="" className='img-responsive bouce-in-animation' style={{maxWidth: "100px", cursor:"pointer"}}  /> 
              </a>
            </div>
          </div>
          <div className="row" style={{position: "relative", top: "-11vh"}}>
            <div className="col-12 text-center">
              <img className="logo-gametime" id="goFS" src= '/assets/logo/logo.svg'  />         
            </div>
            <div className="col-12 text-center" >            
                <input
                  type="text"
                  className={['field bouce-in-animation ', 
                    !validName ? 'field-danger' : ''
                  ].join(' ')}
                  name="name"
                  placeholder="Name"
                  onChange={(e) => { setName(e.target.value); validForm() }}
                />
            </div>
              {roomId === undefined && (
               <div className="col-6 text-end">               
                  <button
                    onClick={access}
                    className="button button-block button-large button-primary btn-main bouce-in-animation "
                  >
                    Create room
                  </button>
                </div>)}
                  <div className={`col-${roomId === undefined ? "6" : "12"} ${roomId === undefined ? "text-start" : "text-center"} `}>
                  
                        <button
                          onClick={play}
                          className="button button-block button-large button-primary btn-main bouce-in-animation "
                        >
                    Play
                  </button>
                
            
            </div>
          </div>
        </div>


  )
}
