import { useEffect, useCallback, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import View from '../../../components/View'

import { Container, ContainerInner } from './styles'
import { io } from 'socket.io-client'
import { Title } from '../../../components/View/styles'
import ScrollView from '../../../components/ScrollView'
import { Button, Modal } from 'react-bootstrap'
import { useSockets } from '../../../context/socket.context'

export default function LobbyRooms() {
  const history = useHistory()

  const { roomId } = useParams<{ roomId: string }>()
  // const { room, teams, onEmitJoin, onEmitStart } = useGameplay()
  
  const [ room, setRoom] = useState({name: "", robotBlue: "", robotYellow: "", robotGreen: "", robotRed: ""})
  const [ robotsBlue, setRobotsBlue ] = useState<string[]>([])
  const [ robotsYellow, setRobotsYellow ] = useState<string[]>([])
  const [ robotsGreen, setRobotsGreen ] = useState<string[]>([])
  const [ robotsRed, setRobotsRed ] = useState<string[]>([])
  const [ quantidade, setQuantidade] = useState(0)
  const [ qtdRobots, setQtdRobots] = useState(0)

  const [btnCopy, setBtnCopy] = useState("Copy")

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { socket } = useSockets();
  
  useEffect(() => {   
  
    
    if(sessionStorage.getItem("tipo-usuario") === "aluno") {
      socket.on("color-robot", (body) => {    
        if(body.name === sessionStorage.getItem("name")) {      
          sessionStorage.setItem("color", body.robot);
          sessionStorage.setItem("nameRoom", body.nameRoom);
          sessionStorage.setItem("nameTeam", body.nameTeam);

        }    
      });           
    } else {
      // Professor
      sessionStorage.setItem("color", "teacher");    
    }

    

    socket.on("play-game", (body) => {    
      if(body === "start") {
        
    socket.on("mistery_box", (body) => {
      sessionStorage.setItem("question-game", JSON.stringify(body));   
      const questionTemp = JSON.parse(sessionStorage.getItem("question-game")!);          
      sessionStorage.setItem("correct_answer", questionTemp.correct_answer);    
      history.replace("/gameplay/magic-box")
    });

    socket.on("hiddenobject", (body) => {
      sessionStorage.setItem("question-game", JSON.stringify(body));    
      const questionTemp = JSON.parse(sessionStorage.getItem("question-game")!);          
      sessionStorage.setItem("correct_answer", questionTemp.correct_answer);    
      history.replace("/gameplay/missing-object")
    });

    socket.on("quiz", (body) => {
      sessionStorage.setItem("question-game", JSON.stringify(body));    
      const questionTemp = JSON.parse(sessionStorage.getItem("question-game")!);          
      sessionStorage.setItem("correct_answer", questionTemp.correct_answer);    
      history.replace("/gameplay/quiz")
    });

    socket.on("ice", (body) => {      
      console.log(body);
      sessionStorage.setItem("team-ice", body);    
      history.replace("/ice")
    });

    socket.on("ice-no-time", (body) => {          
      sessionStorage.setItem("team-ice", body);    
      history.replace("/ice")
    });

    socket.on("roleta", (body) => {
        const roleta = sessionStorage.getItem("roleta") != null ? JSON.parse(sessionStorage.getItem("roleta")!) :  [];
        roleta.push(body);
        sessionStorage.setItem("roleta", JSON.stringify(roleta));  
        sessionStorage.setItem("roleta1", JSON.stringify(body));  
        history.push('/');
        history.replace("/roulette");      
    });

    socket.on("congratulation", (body) => {

      const pontuacao = {
        pontos : [{
          time: "blue", pontos: body.pontosBlue, visivel: body.TeamBlueName.length > 0
        },
        {
          time: "yellow", pontos: body.pontosYellow, visivel: body.TeamOrangeName.length
        },{
          time: "gray", pontos: body.pontosGreen, visivel: body.TeamGreenName.length
        },
        {
          time: "red", pontos: body.pontosRed, visivel: body.TeamRedName.length
        }]
      } 
      sessionStorage.setItem("pontuacao", JSON.stringify(pontuacao));
      history.replace("/congratulation")
    });

    socket.on("point", (body) => {
      sessionStorage.setItem("point", JSON.stringify(body));
      history.replace("/box-gain")
    });

    socket.on("crossing-river", (body) => {
      sessionStorage.setItem("point", JSON.stringify(body));
      history.replace("/crossing-river")
    });

    socket.on("presentation-quiz", (body) => {      
      history.replace("/presentation-quiz")
    });



    socket.on("presentation-misterybox", (body) => {      
      history.replace("/presentation-magicbox")
    });

    socket.on("presentation-hiddenobject", (body) => {
      history.replace("/presentation-missing-object")
    });

    socket.on("ice-vote", (body) => {
      const roleta = sessionStorage.getItem("roleta") != null ? JSON.parse(sessionStorage.getItem("roleta")!) :  [];
      roleta.push(body);
      sessionStorage.setItem("roleta", JSON.stringify(roleta));  

      // sessionStorage.setItem("roleta", JSON.stringify(body));
    });
       // history.replace("/gameplay/quiz");
      }
    });   
 
    
    socket.on("join-robot", (data) => {
        setRobotsYellow(data.robotsYellow);           
        setRobotsBlue(data.robotsBlue);           
        setRobotsGreen(data.robotsGreen);          
        setRobotsRed(data.robotsRed);    
        setQtdRobots(data.quantidade);      
    });

    socket.emit("joined");
 
    
    socket.on("list-rooms", (data : [any]) => {      
      const filtro = data.find(x => x.NameRoom === roomId);      
      setRoom({ name: roomId, robotBlue: filtro.TeamBlueName, robotYellow: filtro.TeamOrangeName, robotGreen: filtro.TeamGreenName, robotRed: filtro.TeamRedName });
      let quant = 0;
      if(filtro.TeamBlueName.length > 0) {
        quant += 1;
      } 
      if(filtro.TeamOrangeName.length > 0) {
        quant += 1;
      }
      if(filtro.TeamGreenName.length > 0) {        
        quant += 1;
      }
      if(filtro.TeamRedName.length > 0) 
      {
        quant += 1;
      }    
      setQuantidade(quant);
    });

  }, [roomId])

  const handleCopyingRoomLink = useCallback(() => {
    toast.success('Room link has been copied to your clipboard.')
  }, [])
  
  const handleStartGame = useCallback(() => {        
    socket.emit("play", sessionStorage.getItem("nameRoom"));
    history.push("/gameplay/" + sessionStorage.getItem("nameRoom"));
  }, [roomId])

  const copy = useCallback(() => {      
      navigator.clipboard.writeText(`https://gametime.internationalschool.global/#/join/${sessionStorage.getItem("nameRoom")}`);
      setBtnCopy("Copied");

  }, [])
  
  return (
    <Container>
 
      <div className="main container">
        {!room ? (
          <View>
            <div className="loading">
              <div className="spinner"></div>
              Loading
            </div>
          </View>
        ) : (
        <ContainerInner className="carton is-scroll" id="view">
          
            <Title className="heading-3 hide-md" style={{padding: "0px"}}>
              <div className="row" style={{padding: "0px"}}>
                <div className="col-10 title-center-lobby">
                  {room.name}
                </div>
                <div className="col-2">                  
                  <img src='/assets/static/icons/human.png' alt="" />
                  <span className='robot-title-number' style={{paddingLeft: "10px"}}>{qtdRobots}</span>
                </div>
              </div> 

            </Title> 
            <div  style={{paddingBottom: "5px"}}>                          
                <hr style={{margin: "0px", height: "2px"}}/>  
            </div>   
            <div className="row">
            
              {room.robotGreen.length > 0 && (<div className={` col-${quantidade === 2 ? '6' : '3'} `} >
                <div className='robot-div div-center ' style={{paddingLeft: "0px !important"}} >
                  <div className="robot-head">
                    <img  src='/assets/static/robot/head_green.png' className='img-head-robot' alt="" />
                  </div>
                  <div className="robot-title">
                    {room.robotGreen}  <br/>
                    <img src='/assets/static/icons/human.png' alt="" style={{ maxWidth: "20px"}} /> {robotsGreen.length}
                  </div> 
                  <hr className='divider-robot' />
                  <div  className="scrool-names">
                  {robotsGreen.map((x,index) => <div className='robot-list' key={index} >  {x} </div> )}
                  </div>
                                  
                </div>
              </div>)}

              
              {room.robotBlue.length > 0 && (<div className={`div-center col-${quantidade === 2 ? '6' : '3'}`} style={{overflow: "auto"}}>
                <div className='robot-div div-center'>
                  <div className="robot-head">
                    <img  src='/assets/static/robot/head_blue.png' alt="" className='img-head-robot' />
                  </div>
                  <div className="robot-title">
                    {room.robotBlue}   <br/>
                    <img src='/assets/static/icons/human.png' alt="" style={{ maxWidth: "20px"}} /> {robotsBlue.length}
                  </div> 
                  <hr className='divider-robot' />
                  <div  className="scrool-names">
                  {robotsBlue.map((x, index) => <div className='robot-list' key={index} >  {x} </div> )}
                </div>
                </div>
              </div>)}
            

              {room.robotYellow.length > 0 && (<div className={`div-center col-${quantidade === 2 ? '6' : '3'}`}>
                <div className='robot-div div-center'>
                  <div className="robot-head">
                    <img  src='/assets/static/robot/head_yellow.png' className='img-head-robot' alt="" />
                  </div>
                  <div className="robot-title">
                    {room.robotYellow}   <br/>
                    <img src='/assets/static/icons/human.png' alt="" style={{ maxWidth: "20px"}} /> {robotsYellow.length}
                  </div> 
                  <hr className='divider-robot' />
                  <div  className="scrool-names">
                  {robotsYellow.map((x, index) => <div className='robot-list' key={index} >  {x} </div> )}
                </div>
                </div>
              </div>)}                    
              {room.robotRed.length > 0 && (<div className={`div-center col-${quantidade === 2 ? '6' : '3'}`}>
                <div className='robot-div div-center'>
                  <div className="robot-head">
                    <img  src='/assets/static/robot/head_red.png' className='img-head-robot' alt="" />
                  </div>
                  <div className="robot-title">
                    {room.robotRed}   <br/>
                    <img src='/assets/static/icons/human.png' alt="" style={{ maxWidth: "20px"}} /> {robotsRed.length}
                  </div> 
                  <hr className='divider-robot' />       
                  <div  className="scrool-names">           
                      {robotsRed.map((x, index) => <div className='robot-list' key={index} >  {x} </div> )}
                                 </div>
                </div>
              </div>)}
            </div>
            
            </ContainerInner> 
          
        )}
        { sessionStorage.getItem("tipo-usuario") !== "aluno" && 
        <footer className="footer">
          <div className="container-fluid">
            <ul className="list-inline">
              <li className="list-item"  style={{paddingRight: "50px"}}>
                <button
                  className="button button-large button-blue"       
                  onClick={handleShow}                                         
                >
                  Room link
                </button>
              </li>
              <li className="list-item" style={{paddingLeft: "50px"}}>
                <button
                  className="button button-large button-blue"
                  onClick={handleStartGame}
                >
                  Play
                </button>
              </li>
            </ul>
          </div>
        </footer> }
      </div>     
      

      <Modal show={show} onHide={handleClose}   aria-labelledby="contained-modal-title-vcenter modal-rounded rounded-0"  size="lg" 
      centered>  
            <Modal.Body className="text-center center-vertical"> 
              <span  className="title-modal" style={{paddingTop: "40px"}}>Share the link with friends!</span> 
              <span className="text-modal-copy">To access the game room, copy the link and share with friends.
                After clicking "Copy", just ctrl+v and the link will be shared. </span> 
                <div className='input-group-copy'  >
                  <input type="text" className="input-copy" disabled={true} value={`https://gametime.internationalschool.global/#/join/${room.name}`} />
                  <button className='button-copy'
                    onClick={copy}
                  >{btnCopy}</button>
                </div>
          </Modal.Body>     
      </Modal>
      
    </Container>
  )
}
