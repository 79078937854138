import { useCallback, useEffect, useState } from 'react'
import gsap, { Back, Power0 } from 'gsap'

import View from '../View'
import Controls from '../Controls'

import { useGameplay } from '../../hook/useGameplay'
import { Container, Content, Text, Image } from './styles'
import Timer from '../Timer'
import { Howl } from 'howler'
import { useSockets } from '../../context/socket.context'
import { useHistory } from 'react-router-dom'

interface IQuestion {
  question_label: string
  question_sound_en: string
  question_sound_pt: string
  question_image: string
  answer_a_label: string
  answer_a_image: string
  answer_a_sound_en: string
  answer_a_sound_pt: string
  answer_b_label: string
  answer_b_image: string
  answer_b_sound_en: string
  answer_b_sound_pt: string
  answer_c_label: string
  answer_c_image: string
  answer_c_sound_en: string
  answer_c_sound_pt: string
  answer_d_label: string
  answer_d_image: string
  answer_d_sound_en: string
  answer_d_sound_pt: string
  correct_answer: string
}


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


export default function Quiz() {


  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const { handleTimer, setQuestion, timer } = useGameplay()
  const [colorRobot, setColorRobot] = useState<string>("head_green.png")
  const [badge, setBadge] = useState<string>("")
  const [displayIce, setDisplayIce] = useState(false)
  const [displayIMG, setDisplayIMG] = useState(false);

  
  const [musica] = useState<Howl>(new Howl({
    src: ['/assets/music/somgame.mp3'],
    autoplay: false,
    loop: false,
    volume: sessionStorage.getItem("musica") ? Number(sessionStorage.getItem("musica")?.toString()) / 100 : 1
  }));


  const [lang, setLang] = useState("ingles");
  const { socket } = useSockets();

  const [questionInner, setQuestionInner] = useState({
    question_label: '',
    question_sound_en: '',
    question_sound_pt: '',
    question_image: '',
    answer_a_label: '',
    answer_a_image: '',
    answer_a_sound_en: '',
    answer_a_sound_pt: '',
    answer_b_label: '',
    answer_b_image: '',
    answer_b_sound_en: '',
    answer_b_sound_pt: '',
    answer_c_label: '',
    answer_c_image: '',
    answer_c_sound_en: '',
    answer_c_sound_pt: '',
    answer_d_label: '',
    answer_d_image: '',
    answer_d_sound_en: '',
    answer_d_sound_pt: '',
    correct_answer: ''
  })

  const changeLanguage = useCallback(() => {
    if (lang === "ingles") setLang("portugues"); else setLang("ingles")
  },
    [lang]
  )

  const router = useHistory()

  useEffect(() => {

    sessionStorage.setItem("urlGarra1", `${"/assets/images/garra.png"}?${global.Date.now()}`);
    sessionStorage.setItem("urlGarra2", `${"/assets/images/garra.png"}?${global.Date.now()}`);
    sessionStorage.setItem("urlGarra3", `${"/assets/images/garra.png"}?${global.Date.now()}`);
    sessionStorage.setItem("urlGarra4", `${"/assets/images/garra.png"}?${global.Date.now()}`);
    
    musica.play();

    

    if (sessionStorage.getItem("roleta")) {

      if(sessionStorage.getItem("color") !== "teacher") {                      
        if(sessionStorage.getItem("TeamFreezed") === sessionStorage.getItem("color")) {
          setDisplayIce(true);
          setBadge("/assets/images/roleta/ice.png");
          sessionStorage.removeItem("TeamFreezed");
        } else {
          const roleta = JSON.parse(sessionStorage.getItem("roleta")!.toString());      
          const index = roleta.findIndex((o:any) => { return o.team === sessionStorage.getItem("color") } );       
          const timeRoleta = index  > -1;
        
          const power = roleta.findIndex((o:any) => { return o.power ===  7 } );
          if(timeRoleta || power > -1) {
            sessionStorage.removeItem("escudo");
          }
          if(index !== -1) {

            if (timeRoleta && roleta[index].power === 1) {        
              setBadge("/assets/images/roleta/block_yellow.png")
            } else if (timeRoleta && roleta[index].power === 2) {        
              setBadge("/assets/images/roleta/hidden2.png")
            } else if (timeRoleta && roleta[index].power === 3) {
              setBadge("/assets/images/roleta/shield.png");
              sessionStorage.setItem("escudo", "true");
            } else if (timeRoleta && roleta[index].power === 4) {        
              setBadge("/assets/images/roleta/block_red.png")
            } else if (timeRoleta && roleta[index].power === 5) {        
              setBadge("/assets/images/roleta/hidden.png")
            } else if (timeRoleta && roleta[index].power === 6) {        
              setBadge("/assets/images/roleta/block_green.png")
            } else if (sessionStorage.getItem("TeamFreezed") === sessionStorage.getItem("color") && roleta[index].power === 7) {        
              setDisplayIce(true);
              setBadge("/assets/images/roleta/ice.png");
              sessionStorage.removeItem("TeamFreezed");          
            } else if (timeRoleta && roleta[index].power === 8) {      
              setBadge("/assets/images/roleta/teacher.png")
            }
        
          }

        }      
        setTimeout(() => {
            sessionStorage.removeItem("roleta");        
        }, 1000);
      }


    }

    if(sessionStorage.getItem("escudo") !== null) {
      setBadge("/assets/images/roleta/shield.png");
    }
    
    if (sessionStorage.getItem("color") === "green") {
      setColorRobot("head_green.png");
    } else if (sessionStorage.getItem("color") === "yellow") {
      setColorRobot("head_yellow.png");
    } else if (sessionStorage.getItem("color") === "red") {
      setColorRobot("head_red.png");
    } else if (sessionStorage.getItem("color") === "blue") {
      setColorRobot("head_blue.png");
    } else if (sessionStorage.getItem("color") === "teacher") {
      setColorRobot("teacher");
    }

 

      const questionTemp = JSON.parse(sessionStorage.getItem("question-game")!) as IQuestion;          
      sessionStorage.setItem("correct_answer", questionTemp.correct_answer);      
    
      setQuestionInner(questionTemp);

      gsap
        .timeline({
          delay: 1,
          onComplete: handleTimer,
        })
        .fromTo(
          '.picture',
          { autoAlpha: 0 },
          { autoAlpha: 1, ease: Power0.easeOut }
        )
        .fromTo(
          '.heading .button-play',
          { autoAlpha: 0, scale: 1.5 },
          { autoAlpha: 1, scale: 1.0, ease: Back.easeOut.config(1.2) }
        )
        .fromTo(
          '.control',
          { autoAlpha: 0, y: 300, height: 0 },
          { autoAlpha: 1, y: 0, height: 'auto', ease: Back.easeOut.config(1.2) }
        )
    
      return function fechar() {
        musica?.stop();
       
      }   
  }, [])


  const [sound, setSound] = useState<null | Howl>()



  useEffect(() => {
    if (questionInner.question_sound_en !== "") {
      setSound(() => {
        return new Howl({
          src: [`${process.env.REACT_APP_API + ((lang === "ingles") ? questionInner.question_sound_en : questionInner.question_sound_pt)}`],
          volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1,
          html5: true,
        })
      })
    } else {
      setSound(null);
    }
  }, [questionInner.question_sound_en, questionInner.question_sound_pt, lang]);

  return (
    <>
      <img src={sessionStorage.getItem("urlGarra1") + ''} alt="" style={{display: "none"}} />
      <img src={sessionStorage.getItem("urlGarra2") + ''} alt="" style={{display: "none"}} />
      <img src={sessionStorage.getItem("urlGarra3") + ''} alt="" style={{display: "none"}} />
      <img src={sessionStorage.getItem("urlGarra4") + ''} alt="" style={{display: "none"}} />
      <div className="missingObject" style={displayIce ? { display: "block" } : { display: "none" }}>
        <img src="/assets/images/ice_frame.png" alt="" className='img-100' />
      </div>


      <div className={"img-modal " + (displayIMG ? "active" : "disabled")} onClick={() => setDisplayIMG(!displayIMG)}>
        <img src={`${process.env.REACT_APP_API + questionInner.question_image}`} alt="" />
        {/* <div className='close-img-modal'></div> */}
      </div>

      <div className="row " style={{ maxWidth: "98%", height: "100%" }}>
        <div className="group-robot col-12 center-flex" style={{ width: "100%" }}>

          <div className='group-robot center-flex' >
            { badge !== "" && <img src={badge} className='badge-robot' alt="" />}
            {(colorRobot === "head_green.png") && (<img src='/assets/static/robot/head_green.png' className="cabeca-game"  alt="" />)}
            {(colorRobot === "head_red.png") && (<img src='/assets/static/robot/head_red.png' className="cabeca-game"  alt="" />)}
            {(colorRobot === "head_yellow.png") && (<img src='/assets/static/robot/head_yellow.png' className="cabeca-game" alt="" />)}
            {(colorRobot === "head_blue.png") && (<img src='/assets/static/robot/head_blue.png' className="cabeca-game" alt="" />)}
            {(colorRobot === "teacher") && (<img src='/assets/images/teacher.png' className="cabeca-game" alt="" />)}

        
            <span className='name-robot'> {colorRobot === "teacher" ? sessionStorage.getItem("name") : sessionStorage.getItem("nameTeam")} </span>


          </div>

        </div>
        <div className="col-1 center-flex" style={{ width: "15%" }} >
          <div className='center-flex'>
            {(lang === "ingles") ? <img src='/assets/static/icons/ingles.png' alt="" className='changeLanguage center-flex' onClick={changeLanguage} /> :
              <img src='/assets/static/icons/portugues.png' alt="" className='changeLanguage center-flex' onClick={changeLanguage} />
            }</div>
        </div>

        <div className="col-10" style={{ width: "75%" }}>

          <Container className="main container" >
            <View>
              <div className="row" >
            
                  <div className="conteudo">
                    <Content center={!!questionInner.question_image}>

                      <div className='btn-play'>
                        {questionInner.question_sound_en && (<img src='/assets/static/buttons/play.png' className='button-play' onClick={() => sound!.play()} />)}
                      </div>
                      <div className='pergunta'>
                      
                        <Text className="heading heading-3">
                          {questionInner.question_label}
                        </Text>
                      </div>

                      {questionInner.question_image && (
                        <Image
                          className={`picture img-max ${questionInner.question_image !== "" && questionInner.answer_a_image !== "" && "picture-pequeno"}`}
                          src={`${process.env.REACT_APP_API + questionInner.question_image}`}
                          onClick={() => setDisplayIMG(!displayIMG)}
                        />
                      )}
                    </Content>
                  </div>
                <div className={`control col-12 d-flex align-items-center justify-content-center controles-zoom ${questionInner.question_image === "" ? " quiz-no-img " : " quiz-with-img "} `}>
                     <Controls col={questionInner.question_image !== "" && questionInner.answer_a_image !== "" ? 3 : 6} data={questionInner} lang={lang} colorRobot={colorRobot} grande={questionInner.question_image === ""}/>
                </div>
                </div>
             
            
            </View>

          </Container>
        </div>
        <div className="col-1" style={{ width: "10%" }} >

          <div style={{ marginLeft: "0vw" }}>
            <Timer />
          </div>


        </div>
      </div>
    </>
  )
}
