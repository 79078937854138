import ScrollView from '../../components/ScrollView'

import { Container, Title } from './styles'

interface IViewProps {
  title?: string
  scroll?: boolean
  children: React.ReactNode,
  page?: string,
  congratulation?:boolean
}

export default function View({ title, scroll, children, page, congratulation }: IViewProps) {
  return (
    <Container style={{height:'100%'}} className={`carton ${scroll && ' is-scroll'} ${congratulation && ' congratulation'} "`} id="view">
      {title && <Title className="heading-3 hide-md title-main">{title}</Title>}      

      {scroll ? (
        <ScrollView>
          {title && <Title className="heading-1 hide flex-md">{title}</Title>}
          {children}
        </ScrollView>
      ) : (
        children
      )}
    </Container>
  )
}
