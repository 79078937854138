import styled from 'styled-components'

export const Container = styled.div`
  border: 3px solid #471f1f;
  border-radius: 0.625rem;
  cursor: pointer;
  opacity: 1;
  transition: 0.3s;
  &:hover {
    background-color: rgba(109, 109, 109, 0.15);
    opacity: 0.85;
  }
`
