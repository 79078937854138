import { useCallback, useEffect, useState } from 'react'
import { useSockets } from '../../context/socket.context'
import Timer from '../Timer'
import View from '../View'
import { Container, Content, Text, Image } from './styles'

export default function Ice() {

  const [ colorRobot, setColorRobot] = useState<string | null>("green")
  const [ power, setPower] = useState("")
  const { socket } = useSockets();
  const [ robots, setRobots] = useState({green: "", red: "", blue: "", yellow: ""})
  const [ shields, setShields] = useState<string[]>([""])
  const [ disableAll, setDisabledAll ] = useState(false)
  const [ selected, setSelected ] = useState(false)
  const [ votes, setVotes ] = useState({green: 0, red: 0, blue: 0, yellow: 0})
  const [ teamFrezzed, setTeamFreezed ] = useState({team: "", nameTeamFreezed: "", show : false, url: "" })

    
  const setVote = useCallback((vote : string) => {        
      if(!shields.includes(vote) && !disableAll) {
        const body = {
          room : sessionStorage.getItem("nameRoom"),
          vote: vote
        }
        socket.emit("vote-ice", body);
        setDisabledAll(true);
      }
      
  }, [shields, disableAll]);


  useEffect(() => {
    socket.on("vote-ice-emit", (body) => {      
      setVotes(body);
    });

    socket.on("ice-vote", (body) => {
        if(sessionStorage.getItem("color") === body.team) {
          setSelected(true);
          sessionStorage.setItem("TeamFreezed", body.team);          
        } else {
          let url = "";
          if(body.team === "green") url = "head_green_f.png";
          if(body.team === "yellow") url = "head_yellow_f.png";
          if(body.team === "red") url = "head_red_f.png";
          if(body.team === "blue") url = "head_blue_f.png";
          setTeamFreezed({team: body.team, nameTeamFreezed: body.nameTeamFreezed, show: true, url: url })          
        }
    });
  }, [votes, selected]);

  
  useEffect(() => {    

    const nameRoom = sessionStorage.getItem("nameRoom");      
    if (sessionStorage.getItem("color") === "green") {
      setColorRobot("head_green.png");
    } else if (sessionStorage.getItem("color") === "yellow") {
      setColorRobot("head_yellow.png");
    } else if (sessionStorage.getItem("color") === "red") {
      setColorRobot("head_red.png");
    } else if (sessionStorage.getItem("color") === "blue") {
      setColorRobot("head_blue.png");
    } else if (sessionStorage.getItem("color") === "teacher") {
      setColorRobot("teacher");
    }
    socket.emit("room", nameRoom);

  }, [])

  useEffect(() => {
    socket.on("group-room", (body) => {
      const getRobots = {green: body.TeamGreenName, red: body.TeamRedName, yellow: body.TeamOrangeName, blue: body.TeamBlueName};
      setRobots(getRobots);
      setShields(body.shields);  
    });
  }, [shields])


  return (
    <>
    <div className="row"  style={{maxWidth:"98%"}}>  

        <div className="row">
        <div className="col-12 center-flex">
            <div className='group-robot center-flex'>
              {(colorRobot === "head_green.png") && (<img src= '/assets/static/robot/head_green.png' className="cabeca-game" alt="" />)}
              {(colorRobot === "head_red.png") && (<img src= '/assets/static/robot/head_red.png' className="cabeca-game" alt="" />)}
              {(colorRobot === "head_yellow.png") && (<img src='/assets/static/robot/head_yellow.png' className="cabeca-game" alt="" />)}
              {(colorRobot === "head_blue.png") && (<img src='/assets/static/robot/head_blue.png' className="cabeca-game" alt="" />)}
              {(colorRobot === "teacher") && (<img src='/assets/images/teacher.png' className="cabeca-game" alt="" />)}    

              <span className='name-robot'> { colorRobot === "teacher" ? sessionStorage.getItem("name") : sessionStorage.getItem("nameTeam")} </span>
            </div>
        </div>
      <div  className="col-1 center-flex" style={{width: "10%"}} ></div>
       <div className="col-10 center-flex" style={{width: "80%"}}>
        <Container className="container">
          
          <View>
            
            <div className="row no-gutters">
                    
                <Content className='row'>
                    <div className='col-12' >
                        
                        <img src="/assets/images/ice/ice_freeze.png" alt="" className='img-ice' />
                        <div className='free-a-team'>
                          {sessionStorage.getItem("team-ice") === "no-time" && 'No team has been frozen.' }
                                                    
                          {!selected && !teamFrezzed.show && sessionStorage.getItem("color") !== sessionStorage.getItem("team-ice") && sessionStorage.getItem("team-ice") !== "no-time" && 'THE TEAM TO BE FREZEED IS BEING CHOSEN…' }
                          {!selected && !teamFrezzed.show && sessionStorage.getItem("color") === sessionStorage.getItem("team-ice") && 'FREEZE A TEAM!' }
                          {selected && !teamFrezzed.show  && 'You’re Freezed' }
                          {(teamFrezzed.show && teamFrezzed.nameTeamFreezed.length > 0) && 'GROUP ' + teamFrezzed.nameTeamFreezed  + " IS FREEZED!"}           
                          {(teamFrezzed.show && teamFrezzed.nameTeamFreezed.length === 0) && 'NO GROUP FREEZED!'}           
                         
                        </div>
                    </div>

                    {teamFrezzed.show  && <div className="col-12"> <img src={'/assets/static/robot/' + teamFrezzed.url}  alt=""  className="change-robot-img change-robot" /> </div>}     
                   
                    {(robots.green.length > 0 && colorRobot !== "head_green.png" && !teamFrezzed.show  && (sessionStorage.getItem("color") === sessionStorage.getItem("team-ice") || colorRobot === "teacher") ) && 
                    <> 
                        <div className={`col-${colorRobot === "teacher" ? '3' : '4'}`}> 
                        <div> 
                        {<img src='/assets/static/robot/head_green.png' alt=""  className={"change-robot-img " + disableAll  || shields.includes("green") ? "" : "change-robot"} onClick={() => sessionStorage.getItem("color") === sessionStorage.getItem("team-ice")  && setVote("green")}   />}
                        </div> 
                        {shields.includes("green") ? <img src="/assets/images/shield.png" alt="" className='shield' /> : <span className='point-ice'>{votes.green}</span>}   
                        </div>
                    </>
                    }
                    {robots.red.length > 0 && colorRobot !== "head_red.png" && !teamFrezzed.show   && (sessionStorage.getItem("color") === sessionStorage.getItem("team-ice") || colorRobot === "teacher") && 
                    <> 
                        <div className={`col-${colorRobot === "teacher" ? '3' : '4'}`}> 
                        <div> 
                        {<img src='/assets/static/robot/head_red.png' alt="" className={"change-robot-img " + disableAll  || shields.includes("red") ? "" : "change-robot"} onClick={() => sessionStorage.getItem("color") === sessionStorage.getItem("team-ice")  &&  setVote("red")} /> }
                        </div> 
                        {shields.includes("red") ? <img src="/assets/images/shield.png" alt="" className='shield' /> : <span className='point-ice'> {votes.red} </span>}  
                        </div>
                    </>
                    }
                    {robots.yellow.length > 0 && colorRobot !== "head_yellow.png" && !teamFrezzed.show  && (sessionStorage.getItem("color") === sessionStorage.getItem("team-ice") || colorRobot === "teacher") && 
                    <> 
                         <div className={`col-${colorRobot === "teacher" ? '3' : '4'}`}> 
                          <div> 
                          {<img src='/assets/static/robot/head_yellow.png' alt="" className={"change-robot-img " + disableAll  || shields.includes("yellow") ? "" : "change-robot"} onClick={() => sessionStorage.getItem("color") === sessionStorage.getItem("team-ice")  && setVote("yellow")} /> }
                          </div> 
                          {shields.includes("yellow") ? <img src="/assets/images/shield.png" alt="" className='shield' /> : <span className='point-ice'> {votes.yellow} </span>}   
                        </div>
                    </>
                    }
                    {robots.blue.length > 0 && colorRobot !== "head_blue.png" && !teamFrezzed.show  && (sessionStorage.getItem("color") === sessionStorage.getItem("team-ice") || colorRobot === "teacher" ) && 
                    <> 
                        <div className={`col-${colorRobot === "teacher" ? '3' : '4'}`}> 
                            <div> 
                            <img src='/assets/static/robot/head_blue.png' alt="" className={"change-robot-img " + disableAll || shields.includes("blue") ? "" : "change-robot"} onClick={() => sessionStorage.getItem("color") === sessionStorage.getItem("team-ice")  && setVote("blue")}  />
                            </div> 
                            {shields.includes("blue") ? <img src="/assets/images/shield.png" alt="" className='shield' /> : <span className='point-ice'> {votes.blue} </span>}   
                        </div>
                    </>
                    }
                    
                </Content>
              
            </div>
          </View>
        </Container>
        </div>
        <div className="col-1" style={{width: "10%"}} >
            <Timer />
        </div>
      </div>     
      </div>
    
  
      </>
  )
}
