import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  #view > .row {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    @media (max-width: 768px) and (orientation: landscape) {
      display: flex;
    }
  }
`
export const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 768px) {
    align-items: flex-end;
  }
  @media (max-width: 768px) and (orientation: landscape) {
    align-items: center;
  }
`
export const Text = styled.h1`
  text-align: center;
  z-index: 1;
  position: relative;
  background-color: #a054a0;
  width: auto;
  padding: 15px 25px;
  border-radius: 10px;
  color: #fff;
  border: 3px solid #471f1f;
`
export const Image = styled.img`
  width: 90%;
  height: 90%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
  @media (max-width: 768px) {
    height: 100%;
  }
`
export const Controllers = styled.div`
  width: 100%;
  max-width: 750px;
  .checkcad-label {
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
