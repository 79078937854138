import styled from 'styled-components'

import heading from '../../static/heading.png'

export const Container = styled.div`
  font-size: 4vmin;
  margin: auto;
  text-align: center;
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  #view {
    color: #fff;
    background-color: #a054a0;
    @media (max-width: 768px) {
      padding: 0 15px !important;
    }
  }
  #scroll {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 1.3;
  }
`

export const Title = styled.div`
  color: #471f1f;
  background-image: url(${heading});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  max-width: 600px;
  height: 110px;
  margin: 0 auto 30px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateX(20px);
  @media (max-width: 768px) {
    margin: 75px auto 25px;
    height: 80px;
    transform: none;
  }
  img {
    position: absolute;
    height: 90%;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 768px) {
      left: 50%;
      top: -75px;
      transform: translateX(-50%);
    }
  }
`

export const Content = styled.div`
  margin: auto;
  padding: 30px 0;
`
