import { createGlobalStyle } from 'styled-components'

import background from '../static/bg.png'

export default createGlobalStyle`
  @keyframes spinner {
    to { transform: rotate(360deg) }
  }
  
  html, body, #root {
    height: 100%;
  }

  body {
    color: #471f1f;
    font-family: 'Exo 2 Black Regular', sans-serif;
    background-color: #4a1693;
    background-image: url(${background});
    background-size: 120%;
    background-position: center;
  }

  hr {
    background-color: #b7b7b7;
    width: 100%;
    height: 3px;
    border: 0;
    border-radius: 5rem;
    margin: 2rem 0;
  }

  .main {
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
  }

  .heading-1 {
    font-family: 'Earths Mightiest Punch' !important;
  }

  .d-flex {
    display: flex;
  }
  .align-items-center {
    align-items: center;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .justify-content-center {
    justify-content: center;
  }

  .position-relative {
    position: relative;
  }

  .m-0 {
    margin: 0 !important;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  .mb-5 {
    margin-bottom: 3.875rem !important;
  }

  .text-center {
    text-align: center !important;
  }
  .text-uppercase {
    text-transform: uppercase !important;
  }
  
  .input-group {
    display: flex;
    .field {
      &:nth-child(1) {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:nth-child(2) {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:focus {
        z-index: 2;
      }
    }
  }

  .spinner {
    vertical-align: -.125em;
    width: 2rem;
    height: 2rem;
    border: .25em solid #37106e;
    border-right-color: transparent;
    border-radius: 50%;
    display: inline-block;
    animation: .75s linear infinite spinner;
  }

  .loading {
    color: #37106e;
    font-size: 22px;
    text-align: center;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    .spinner {
      margin-bottom: 15px;
    }
  }
`
