import { useState } from 'react';
import Option from './Option'

import { Container } from './styles'

interface IData {
  answer_a_label?: string
  answer_a_image?: string
  answer_a_sound_en?: string
  answer_a_sound_pt?: string
  answer_b_label?: string
  answer_b_image?: string
  answer_b_sound_en?: string
  answer_b_sound_pt?: string
  answer_c_label?: string
  answer_c_image?: string
  answer_c_sound_en?: string
  answer_c_sound_pt?: string
  answer_d_label?: string
  answer_d_image?: string
  answer_d_sound_en?: string
  answer_d_sound_pt?: string
}

interface IControlsProps {
  col: number
  data: IData
  lang: string
  colorRobot: string,
  grande?: boolean,
  game?: string;
}


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


export default function Controls({ col, data, lang, colorRobot, grande = false, game = "" }: IControlsProps) {

  
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
  return (

    <Container className={`${game === "magic" ? "option-magic center-flex w-100" : "row"} ${game === "missing" && "missing-object"} `}>
      <div className={`col ${game ==="magic" ? "col-12  pd-magic" : `col-md-${col}`} ${data.answer_a_label && data.answer_a_image && " inner-img-label-control "} `} 
      style={{paddingBottom: data.answer_a_image !== "" && game === "" && col === 6 && windowDimensions.height > 420 ? "25px" : "10px"}}>
        <Option
          color="blue"
          letter="A"
          label={data.answer_a_label}
          image={data.answer_a_image}
          sound_en={data.answer_a_sound_en}
          sound_pt={data.answer_a_sound_pt}
          lang={lang}
          colorRobot={colorRobot}
          grande = {grande}
          game={game}
        />
      </div>
      <div className={`col ${game ==="magic" ? "col-12  pd-magic" : `col-md-${col}`} ${data.answer_a_label && data.answer_a_image && " inner-img-label-control "} `} style={{paddingBottom: data.answer_a_image !== "" && game === "" && col === 6  && windowDimensions.height > 420 ? "25px" : "10px"}}>
        <Option
          color="yellow"
          letter="B"
          label={data.answer_b_label}
          image={data.answer_b_image}
          sound_en={data.answer_b_sound_en}
          sound_pt={data.answer_b_sound_pt}
          lang={lang}
          colorRobot={colorRobot}
          grande = {grande}
          game={game}
        />
      </div>
      <div className={`col ${game ==="magic" ? "col-12 pd-magic" : `col-md-${col}`} ${data.answer_a_label && data.answer_a_image && " inner-img-label-control "} `} style={{paddingBottom: windowDimensions.height <= 768 && game !== "missing"  && col === 6  && windowDimensions.height > 420 ? "25px" : "10px"}}>
        <Option
          color="green"
          letter="C"
          label={data.answer_c_label}
          image={data.answer_c_image}
          sound_en={data.answer_c_sound_en}
          sound_pt={data.answer_c_sound_pt}
          lang={lang}
          colorRobot={colorRobot}
          grande = {grande}
          game={game}
        />
      </div>
      <div className={`col ${game ==="magic" ? "col-12  pd-magic" : `col-md-${col}`} ${data.answer_a_label && data.answer_a_image && " inner-img-label-control "} `} style={{paddingBottom: windowDimensions.height <= 768 && game !== "missing"  && col === 6  && windowDimensions.height > 420 ? "25px" : "10px"}}>
        <Option
          color="red"
          letter="D"
          label={data.answer_d_label}
          image={data.answer_d_image}
          sound_en={data.answer_d_sound_en}
          sound_pt={data.answer_d_sound_pt}
          lang={lang}
          colorRobot={colorRobot}
          grande = {grande}
          game={game}
        />
      </div>
    </Container>
  )
}
