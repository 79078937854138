import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import gsap, { Back } from 'gsap'
import { useSockets } from '../context/socket.context'


interface IRoom {
  id: string
  name: string
  timer: number
}

export interface ITeam {
  type: 'blue' | 'yellow' | 'green' | 'red'
  name: string
  score: number
  members: {
    id: string
    name: string
  }[]
}

interface IVote {
  team: 'blue' | 'yellow' | 'green' | 'red'
  data: {
    A: number
    B: number
    C: number
    D: number
  }
}

interface IGameplayProviderProps {
  children: React.ReactNode
}

type IRouteGameplay =
  | 'presentation'
  | 'quiz'
  | 'mistery_box'
  | 'hidden_object'
  | 'crossingTheRiver'

interface IGameplayContextData {
  route: IRouteGameplay
  room: IRoom | null
  timer: number
  timerText: string
  teams: ITeam[]
  question: any
  setQuestion:any
  votes: IVote[]
  correctAnswer: any
  handleTimer: () => void
 
}

const GameplayContext = createContext({} as IGameplayContextData)

export function GameplayProvider({ children }: IGameplayProviderProps) {
  const [route, setRoute] = useState<IRouteGameplay>('presentation')

  const [room, setRoom] = useState<IRoom | null>(null)
  const [timer, setTimer] = useState(60)
  const [timerText, setTimerText] = useState('00:00')
  const [teams, setTeams] = useState<ITeam[]>([])
  const [question, setQuestion] = useState()
  const [reset, setReset] = useState(false)
  const [votes, setVotes] = useState<IVote[]>([ { team: 'blue', data: { A: 0, B: 0, C: 0, D: 0 } }])

  const [congratulation, setCongratulation] = useState({})
  const [correctAnswer, setCorrectAnswer] = useState<string | null>()
  const { socket } = useSockets();

  const handleTimer = useCallback((tempo = 0, tempoTotal = 0) => {
    if(tempoTotal === 0 && tempoTotal === 0) return;
    const element = document.querySelector<HTMLDivElement>('#timer-arrow')

    if (element) {

        // setCorrectAnswer(null);
        
        const progress = ((tempo * -1) * 360) /  tempoTotal;
        element.style.transform = `rotate(${progress}deg)`

        const minutes = Math.floor(tempo / 60)
        const seconds = tempo % 60
        const countOfTime = `
          ${(minutes < 10 ? '0' : '') + minutes.toString()}:
          ${(seconds < 10 ? '0' : '') + seconds.toString()}`

        setTimerText(countOfTime)
         
        if (tempo === 0) {     
          setCorrectAnswer(sessionStorage.getItem("correct_answer")!.toString());      
        }
    }
  }, [timer])

  const handlePageTransition = useCallback(
    (page: any) => {
      gsap.fromTo(
        '#transition',
        0.7,
        {
          scale: 0,
          delay: 10,
        },
        {
          scale: 1,
          ease: 'power4.inOut',
          onComplete: () => {
            setRoute(page)

            gsap.fromTo(
              '#transition',
              0.7,
              { scale: 1 },
              { scale: 0, ease: 'power4.inOut' }
            )
          },
        }
      )
    },
    [route]
  )

  const handleGame = useCallback((page: any) => {
    handlePageTransition('presentation')

    setTimeout(() => {
      handlePageTransition(page)
    }, 1000 * Number(process.env.REACT_APP_TIMER_PRESENTATION))
  }, [])

  useEffect(() => {    
    let timeInicia = true;
    let tempoInicial = 0;
    socket.on('time', timer_callback => {        
      if(timeInicia) {
        tempoInicial = timer_callback;
        timeInicia = false;
      }
      setCorrectAnswer(null);
      setTimer(timer_callback);   
      handleTimer(timer_callback, tempoInicial);
      
    })
  }, [])

  return (
    <GameplayContext.Provider
      value={{
        route,
        room,
        timer,
        timerText,
        teams,
        question,
        correctAnswer,
        votes,  
        setQuestion,
        handleTimer
      }}
    >
      {children}
    </GameplayContext.Provider>
  )
}

export function useGameplay(): IGameplayContextData {
  const context = useContext(GameplayContext)

  if (!context) {
    throw new Error('useGameplay must be used within an GameplayProvider.')
  }

  return context
}
