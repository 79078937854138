import { isError, useQuery } from 'react-query'
import { api } from '../../services/api'

import View from '../../components/View'
import CardRoom from '../../components/CardRoom'

import { Container } from './styles'



import { io } from 'socket.io-client'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSockets } from '../../context/socket.context'

interface IRoom {
  id: string
  name: string
  match: string
}

export default function Rooms() {

  const [ isLoading, setIsLoading] = useState(true)
  const [ isError, setIsError] = useState(false)
  const [ data, setData] = useState([{id:"", name: "", match: ""}])
  const { socket } = useSockets();
  
  useEffect(() => {
        
    socket.on("list-rooms", (body) => {
      const arrayData: ((prevState: { id: string; name: string; match: string }[]) => { id: string; name: string; match: string }[]) | { id: any; name: any; match: string }[] = []
      body.forEach((x:any) => {
        const dataInner = {
          id: x.SocketId,
          name: x.NameRoom,
          match: x.NameRoom
        }
        arrayData.push(dataInner);
        
      });
      setData(arrayData); 
      if(data.length > 0) {
        setIsLoading(false)
      }      
    }); 
    socket.emit("list-rooms");

  }, [])

  return (
    <Container>
      <div className="main container">
        {isLoading ? (
          <View>
            <div className="loading">
              <div className="spinner"></div>
              Loading
            </div>
          </View>
        ) : isError ? (
          <View>
            <div className="loading">
              <div className="spinner"></div>
              We had a problem here, please try again later. If the problem
              persists, contact an administrator.
            </div>
          </View>
        ) : !data || data.length === 0 ? (
          <View>
            <div className="loading">
              <img src='/assets/static/hidden_flag.png' />
              There are currently no open rooms.
            </div>
          </View>
        ) : (
          <View title="Room list" scroll>
            <div className="row">
              {data.map(({ id, match, name }) => (
                <div className="col-12 col-md-6 col-lg-4" key={id}>
                  <CardRoom match={match} name={name} />
                </div>
              ))}
            </div>
          </View>
        )}
      </div>
    </Container>
  )
}
