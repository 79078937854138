import { HashRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { QueryClientProvider, QueryClient } from 'react-query'

import { PlayerProvider } from './hook/usePlayer'

import { GameplayProvider } from './hook/useGameplay'

import Routes from './routes'
import Resizing from './templates/Resizing'
import GlobalStyle from './styles/global'
import { useEffect } from 'react'

const queryClient = new QueryClient()

export default function App() {

  return (
   
    <QueryClientProvider client={queryClient}>
      
        <PlayerProvider>
          <GameplayProvider>
            
              <HashRouter>
                <Routes />
                <GlobalStyle />

                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </HashRouter>
            
          </GameplayProvider>
        </PlayerProvider>
      
    </QueryClientProvider>

  )
}
