import styled from 'styled-components'


export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start !important;  
  #view > .row {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    @media (max-width: 768px) and (orientation: landscape) {
      display: flex;
    }
    > .col.d-flex {
      margin-top: -5px;
      margin-bottom: -5px;
      flex-direction: column;
    }
  }
  .heading,
  .picture,
  .control {
    visibility: hidden;
  }
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  
  @media (max-width: 768px) {
    align-items: flex-end;
  }
  @media (max-width: 768px) and (orientation: landscape) {
    align-items: flex-start;
  }
`

export const Text = styled.h1`
  color: #fff;
  text-align: center;
  background-color: #a054a0;
  width: auto;
  border: 3px solid #471f1f;
  border-radius: 10px;
  padding: 15px 25px;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
`

export const Image = styled.img`
  width: 90%;
  height: 30vh;
  margin: auto;
  position: absolute;
  margin-top: 95px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
  @media (max-width: 768px) {
    height: 100%;
  }

  @media (max-height: 768px) {
    margin-top: 80px !important;
  }
  @media (-webkit-device-pixel-ratio: 1.50) {
    margin-top: 65px  !important;
    max-height: 180px;
  }
`
