import View from '../View'
import { Container, } from './styles'
import React, { useCallback, useEffect, useState } from 'react';
import { Howl } from 'howler';


export default function PresentationCaixaMagica() {


  const [lang, setLang] = useState("ingles");
  const [sound] = useState<Howl>(new Howl({
    src: ["/assets/music/quiz/musica.mp3"],
    volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1,
    autoplay: false
  }))
  const [english] = useState<Howl>(new Howl({
    src: ["/assets/music/quiz/magicbox.mp3"],
    volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1,
    autoplay: false
  }))

  const [portugues] = useState<Howl>(new Howl({
    src: ["/assets/music/quiz/caixamagica.mp3"],
    volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1,
    autoplay: false
  }))


  const changeLanguage = useCallback(() => {
    if (lang === "ingles") setLang("portugues"); else setLang("ingles")
  },
    [lang]
  )

  useEffect(() => {
    sound.play();
    return function limpar () {      
      sound.stop();
    };
  }, [])

  const playQuestion = useCallback(() => {
    if (lang === "ingles") {
      english.play();
    } else {
      portugues.play();
    }
  },
    [lang]
  )

  return (
    <>
      <div className="row " style={{ maxWidth: "98%", height: "100%" }}>
        <div className="group-robot col-12 center-flex" style={{ width: "100%" }}>


        </div>
        <div className="col-1" style={{ width: "15%" }} >
          <div className='center-flex'>
            {(lang === "ingles") ? <img src='/assets/static/icons/ingles.png' alt="" className='changeLanguage center-flex' onClick={changeLanguage} /> :
              <img src='/assets/static/icons/portugues.png' alt="" className='changeLanguage center-flex' onClick={changeLanguage} />
            }</div>
        </div>

        <div className="col-10" style={{ width: "70%" }} >

          <Container className="main container center-flex" style={{ display: "flex", flexDirection: "column" }} >
            <div>
              <div className='text-center' style={{ display: "flex", flexDirection: "column", height: '100%' }} >
                <div className='group-heading-presentation'>
                  <img src="/assets/images/magicbox.png" alt="" className='heading-presentation'  />
                  <img src="/assets/static/sound.png" alt="" className='sound-heading sound-magic' onClick={playQuestion}  />
                </div>

                <div className='text-presentation'>
                  {lang !== "ingles" && <span> O jogador dará seu palpite sobre qual objeto acredita estar dentro da Caixa Mágica. Ele receberá duas dicas e depois dará sua resposta clicando em um dos botões coloridos. </span>}
                  {lang === "ingles" && <span> In this game, the player must take a guess about which object is inside the Magic Box. After given two hints, the player must pick an answer by clicking on one of the colored buttons. </span>}
                </div>
              </div>
            </div>

          </Container>
        </div>
        <div className="col-1" style={{ width: "15%" }} >

        </div>
      </div>
    </>
  )
}
