import { Howl } from 'howler'
import { useEffect, useState } from 'react'
import View from '../View'
import { Container, Content, Text, Image } from './styles'

export default function Roulette() {

  const [ colorRobot, setColorRobot] = useState<string | null>("green")
  const [ nameTime, setNameTime] = useState<string | null>("")
  const [ power, setPower] = useState("")
  const [sound, setSound ] = useState<Howl>(new Howl({
    src: ['/assets/music/roleta.mp3'],
    autoplay: false,
    loop: true,
    volume:  sessionStorage.getItem("musica") ? Number(sessionStorage.getItem("musica")?.toString()) / 100 : 1,
}));

useEffect(() => { 
  return function limpar () {      
    sound.stop();
  };
}, []); 

  useEffect(() => {
    sound.play();
    const roleta = JSON.parse(sessionStorage.getItem("roleta1")!.toString());
    setColorRobot(roleta.team);
    setNameTime(roleta.nomeTime);
    if (roleta.team === "green") {
      setColorRobot("head_green.png");
    } else if (roleta.team === "yellow") {
      setColorRobot("head_yellow.png");
    } else if (roleta.team === "red") {
      setColorRobot("head_red.png");
    } else if (roleta.team === "blue") {
      setColorRobot("head_blue.png");
    }  else {
      setColorRobot("teacher");
    }

    setPower(`sec_anim${roleta.power} 7s normal forwards`);
  }, [nameTime, colorRobot])
  
  return (
  <div className="row"  style={{maxWidth:"98%"}}>  
          <div className="col-12 center-flex" style={{width:"100%"}}>
      <div className="col-12">
      <div className='group-robot center-flex'>
            {(colorRobot === "head_green.png") && (<img src= '/assets/static/robot/head_green.png' className="cabeca-game"  alt="" />)}
            {(colorRobot === "head_red.png") && (<img src= '/assets/static/robot/head_red.png' className="cabeca-game"  alt="" />)}
            {(colorRobot === "head_yellow.png") && (<img src= '/assets/static/robot/head_yellow.png' className="cabeca-game" alt="" />)}
            {(colorRobot === "head_blue.png") && (<img src='/assets/static/robot/head_blue.png' className="cabeca-game"  alt="" />)}
            {(colorRobot === "teacher") && (<img src='/assets/images/teacher.png' className="cabeca-game"  alt="" />)}  
            <span className='name-robot'> { colorRobot === "teacher" ? sessionStorage.getItem("name") : nameTime} </span>
          </div>
      </div>
       <div className="col-12">
        <Container className="container">
          
          <View>
            
            <div className="row no-gutters">
              <div className="col col-12 col-lm-6">
                <Content>
                  <Image src= '/assets/static/roulette.png' />
                  <Image src= '/assets/static/roulette_arrow.png'  style={{animation: power}} />
                </Content>
              </div>
            </div>
          </View>
        </Container>
        </div>
      </div>
      </div>
  )
}
