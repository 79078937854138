import styled, { css } from 'styled-components'

import blue from '../../../static/robot/head_blue.png'
import yellow from '../../../static/robot/head_yellow.png'
import green from '../../../static/robot/head_green.png'
import red from '../../../static/robot/head_red.png'
import hiddenFlag from '../../../static/hidden_flag.png'
import sound from '../../../static/sound.png'
import correct from '../../../static/correct.png'
import errado from '../../../static/errado.png'


interface IBadgeProps {
  type: 'blue' | 'yellow' | 'green' | 'red' | 'hidden_flag'
}

const types = {
  blue: blue,
  yellow: yellow,
  green: green,
  red: red,
  hidden_flag: hiddenFlag,
}

export const Container = styled.label`
  height: 100%;
  
  .checkcad-label {
    min-height: 45px;
    height: 95%;    
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    
    @media (max-width: 768px) {
      min-height: 64px;
      padding: 10px;
      
    }
    @media (max-height: 768px) {
      max-height: 300px;
      // top: 22%;
      padding: 5px 5px;
      height: 100%;  
    }
    @media (max-height: 420px) {
      min-height: 50px;
      max-height: 100px;
      // top: 30%;      
      padding: 5px 2px;
    }
    @media (max-height: 754px) {      
      // top: 44%;
      padding: 2px 2px 2px 2px;
    }
  }
  .checkcad-checked {
    background-image: url(${correct});
    background-repeat: no-repeat;
    background-position: center;
    height: 48px;
    width: 52px;
    position: absolute;
    right: -23px;
    top: -23px;
    z-index: 3;
    cursor: pointer;
    opacity: 1;
    pointer-events: none;
    visibility: hidden;
  }

  .checkcad-checked-errado {
    filter: grayscale(0%) !important;
    background-image: url(${errado});
    background-repeat: no-repeat;
    background-position: center;
    height: 48px;
    width: 52px;
    position: absolute;
    right: -23px;
    top: -23px;
    z-index: 3;
    cursor: pointer;
    opacity: 1;
    pointer-events: none;
    visibility: hidden;
  }
`

export const Picture = styled.span`
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  
  @media (max-width: 768px) {
    margin-bottom: 5px;
    max-height: 100px;
    img {
      padding: 3px;
    }
  }
  @media (max-height: 768px) {
    max-height: 200px;
  }
  img {
    width: 100%;
    max-heigth: 280px;
    height: 100%;
    margin: auto;
    padding: 0px;
    object-fit: scale-down;
    
    @media (max-width: 768px) and (orientation: landscape) {
      max-width: 100%;
      height: 90%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
`

export const Content = styled.div`
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  @media (max-width: 768px) {
    padding: 3px;
  }
  .badge:not(:last-child) {
    margin-bottom: 7px;
    @media (max-width: 768px) {
      margin-bottom: 2px;
    }
  }
`

export const Badge = styled.span<IBadgeProps>`
  color: #471f1f;
  background-color: #fff;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 0 10px 0 0;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    background-image: url(${props => types[props.type]});
    background-repeat: no-repeat;
    background-size: contain;
    width: 25px;
    height: 25px;
    margin-right: 5px;
    display: inline-block;
  }
`

export const Sound = styled.span`
  background-color: #898989;
  background-image: url(${sound});
  background-repeat: no-repeat;
  background-position: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-size: 60%;
  border: 3px solid #383838;
  position: absolute;
  left: -18px;
  top: -12px;
  z-index: 1;
  cursor: pointer;  
  @media (max-height: 420px) {    
    width: 26px !important;
    height: 26px !important;
  }
`
