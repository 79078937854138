import { useHistory } from 'react-router-dom';
import View from '../View'
import { Title } from '../View/styles';
import { Container } from "./style";

export default function Credits() {

    const router = useHistory()
    return (
        <div className="div-main">
            <Container >
                <div className='text-center' style={{ width: "15%", justifyItems: "start", justifyContent: "start" }}>
                    <img src="/assets/images/buttons/back.png" alt="" onClick={router.goBack} className='btn-leave' style={{ marginTop: "-65vh" }} />
                </div>
                <div style={{ width: "70%" }}>
                    <View title="Credits" scroll>
                        <div className='text-center ' style={{ fontSize: "24px" }}>
                        <b>CEO:</b> <br/>
Ulisses Cardinot<br/>
<br/>
<b>CPO:<br/></b>
Virginia Garcia<br/>
<br/>
<b>Publishing and Quality Manager:<br/></b>
Regina Madureira<br/>
<br/>
<b>Publishing Coordinator:<br/></b>
Eduardo Oliveira<br/>
<br/>
<b>Publishing | Supervisor:<br/></b>
Henrique Macedo<br/>
<br/>
<b>Content Editor:<br/></b>
Stefany Daltro Teixeira<br/>
<br/>
<b>Content Authors:<br/></b>
Luciana Gomide<br/>
Fernanda Porto Schofield<br/>
<br/>
<b>IT Manager:<br/></b>
Ramon Anzai<br/>
<br/>
<b>EdTech:<br/></b>
Cleber Carvalho<br/>
Nicolas Ariel Duarte Almeida<br/>
Thales William Nogueira da Silva<br/>
Ailana Alves Barbosa<br/>
<br/>
<b>Images Copyright:<br/></b>
Shutterstock<br/>
Dreamstime<br/>
123 RF<br/>
Freepik<br/>
Istockphotos<br/>
<br/>
Copyright @ 2022<br/>
Produced and published in Brazil by International School Serviços de
Ensino, Treinamento e Editoração Franqueadora S.A.<br/>
<br/>
This work is part of the International School Bilingual Programme.
ALL RIGHTS RESERVED. No part of this work, covered by the copyright
herein, may be reproduced, transmitted, stored or used in any form,
by graphic, electronic, or mechanical means, including, but not limited
to photocopying, recording, scanning, digitizing, taping, distributing
online, on information networks or information storage and retrieval
systems, without the prior written permission of the publisher, except as
permitted for use by an International School student in his or her own
home or property.
For permissions to use material from this product, submit all requests to
direitos.autorais@internationalschool.global.
<br/><br/>
<b>International School Serviços de Ensino, Treinamento e Editoração
Franqueadora S.A.</b>
182, Dr. Amâncio de Carvalho Street - 4th floor - room 411 - Vila Mariana
Postcode: 04012-080 - São Paulo - SP

                        </div>
                    </View>
                </div>

            </Container>
        </div>
    )
}