import { Switch, Route, Redirect } from 'react-router-dom'

import Main from '../pages/Main'
import Rooms from '../pages/Rooms'
import CreateRooms from '../pages/Rooms/Create'
import LobbyRooms from '../pages/Rooms/Lobby'
import Gameplay from '../pages/Gameplay'
import PresentationQuiz from '../components/PresentationQuiz'
import PresentationCaixaMagica from '../components/PresentationMagicBox'
import PresentationMissingObject from '../components/PresentationMissingObject'


import Quiz from '../components/Quiz'
import MagicBox from '../components/MagicBox'
import Ice from '../components/Ice'
import MissingObject from '../components/MissingObject'
import Roulette from '../components/Roulette'
import Congratulation from '../components/Congratulation'
import BoxGain from '../components/BoxGain'
import Config from '../components/Config'
import Credits from '../components/Credits'
import CrossingTheRiver from '../components/CrossingTheRiver'

export default function Routes() {
  return (

    <Switch>
      <Route path="/" component={Main} exact />
      <Route path="/rooms" component={Rooms} exact />
      <Route path="/ice" component={Ice} exact />
      <Route path="/rooms/create" component={CreateRooms} exact />
      <Route path="/config" component={Config} exact />
      <Route path="/credits" component={Credits} exact />
      <Route path="/crossing-river" component={CrossingTheRiver} exact />

      <Route path="/rooms/:roomId" component={LobbyRooms} />    
      <Route path="/gameplay/magic-box" component={MagicBox} />
      <Route path="/box-gain" component={BoxGain} />
      <Route path="/gameplay/quiz" component={Quiz} />
      <Route path="/gameplay/missing-object" component={MissingObject} />
      <Route path="/presentation-quiz" component={PresentationQuiz} />
      <Route path="/presentation-magicbox" component={PresentationCaixaMagica} />
      <Route path="/presentation-missing-object" component={PresentationMissingObject} />      

      <Route path="/gameplay/:roomId" component={Gameplay} />
      <Route path="/join/:roomId" component={Main} />

      <Route path="/roulette" component={Roulette} />
      <Route path="/congratulation" component={Congratulation} />
      <Redirect path="/*" to="/" />
    </Switch>

  )
}
