import styled from 'styled-components'

export const View = styled.div`
  padding: 32px 48px;
  overflow-x: hidden !important;
  margin-bottom: 0 !important;
  @media (max-width: 768px) {
    padding: 0 !important;
  }
`

export const TrackVertical = styled.div`
  width: 25px !important;
  right: 0;
  top: 40px;
  bottom: 40px;
  &:before {
    content: '';
    background-color: #471f1f;
    width: 7px;
    border-radius: 5rem;
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    transform: translateX(-60%);
    pointer-events: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
`

export const TrackHorizontal = styled.div`
  display: none;
`

export const ThumbVertical = styled.div`
  background-color: #ffcb00;
  border: 4px solid #471f1f;
  border-radius: 10px;
  box-sizing: border-box;
`
