import { Scrollbars, ScrollbarProps } from 'react-custom-scrollbars'

import { View, TrackVertical, TrackHorizontal, ThumbVertical } from './styles'

interface IScrollViewProps extends ScrollbarProps {
  children: React.ReactNode
}

export default function ScrollView({ children, ...rest }: IScrollViewProps) {
  return (
    <Scrollbars
      renderTrackVertical={props => <TrackVertical {...props} />}
      renderTrackHorizontal={props => <TrackHorizontal {...props} />}
      renderThumbVertical={props => <ThumbVertical {...props} />}
      renderView={props => <View id="scroll" {...props} />}
      hideTracksWhenNotNeeded
      children={children}
      {...rest}
    />
  )
}
