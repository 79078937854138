import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  #view > .row {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    @media (max-width: 768px) and (orientation: landscape) {
      display: flex;
    }
  }
`
