import React from 'react'
import ReactDOM from 'react-dom'

import 'normalize.css'
import 'react-toastify/dist/ReactToastify.css'

import App from './App'
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(

    <App />
  ,
  document.getElementById('root')
)
