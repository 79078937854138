import styled from 'styled-components'

interface IContentProps {
  center?: boolean
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start !important;
  #view > .row {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    @media (max-width: 768px) and (orientation: landscape) {
      display: flex;
    }
    > .col.d-flex {
      margin-top: -5px;
      margin-bottom: -5px;
      flex-direction: column;
    }
  }
`

export const Content = styled.div<IContentProps>`
  height: 100%;
  display: flex;
  align-items: ${props => (props.center ? 'flex-start' : 'center')};
  justify-content: center;
`

export const Text = styled.h1`
  color: #4A2021;
  font-size: 40px;
  text-align: center;
  width: auto;
  max-width: 750px;    
  
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  @media (max-width: 768px) {
    font-size: 30px;
  }

  @media (max-height: 768px) {
    font-size: 30px;
  }
  @media (max-width: 768px) and (orientation: landscape) {
    font-size: 20px;
  }
`

export const Image = styled.img`
  width: 75%;
  height: 75%;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 10%;
  margin-top: 50px;
  object-fit: contain;
  height: 32vh;
  bottom: 33vh;
  cursor: pointer;
  @media (max-height: 768px) {
    margin-top: 100px;
    min-height: 120px;
    max-height: 210px;    
    height: 24vh;
    bottom: 33vh;    
  }

  /* zoom 150% */
  @media (-webkit-device-pixel-ratio: 1.50) {    
    top: 5%;     
  }
`
