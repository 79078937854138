import styled from 'styled-components'


export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;  
  #view {
    background-size: auto 102%;
    background-position: center;
    overflow: hidden;
  }
`