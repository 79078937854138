import styled from 'styled-components'

import heading from '../../static/heading.png'

export const Container = styled.div`
height: 75vh !important;
border: 7px solid white !important;
background-color: rgba(160, 84, 160, 0.7);
border-radius: 32px !important;
padding: 32px !important;
color: white;
/* @media (max-width: 768px) and (orientation: landscape) {
  height: 100%;
  border-width: 4px !important;
  border-radius: 10px !important;
  padding: 0.5rem !important;
} */
/* @media (max-width: 576px) { */
@media (max-height: 768px) {
  height: 85%;
}
@media (max-width: 768px) {
  height: 100%;
  border-width: 4px !important;
  border-radius: 15px !important;
  padding: 6px !important;
}
&.is-scroll > div {
  border-radius: 10px !important;
}
`

export const Title = styled.div`  
  color: #471f1f;
  background-image: url(${heading});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  max-width: 600px;
  height: 110px;
  margin: 0 auto 30px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateX(20px);
  @media (max-width: 768px) {
    margin: 75px auto 25px;
    height: 80px;
    transform: none;
  }
  img {
    z-index: 1000;
    cursor:pointer;
    position: absolute;
    height: 90%;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 768px) {
      left: 50%;
      top: -75px;
      transform: translateX(-50%);
    }
  }
`

export const Content = styled.div`
  margin: auto;
  padding: 30px 0;
`
