import { useState, useCallback, useEffect } from 'react'
import gsap, { Back, Power0 } from 'gsap'

import View from '../View'
import Controls from '../Controls'
import { useGameplay } from '../../hook/useGameplay'
import { Container, Content, Text, Image } from './styles'
import { useHistory } from 'react-router-dom'
import { useSockets } from '../../context/socket.context'
import Timer from '../Timer'
import { Howl } from 'howler'

interface IHeading {
  label: string
  sound_en: string
  sound_pt: string
}

interface Questions {
  correct_answer: string
  question_image: string
  tip_a_label: string
  tip_a_sound_en:  string
  tip_a_sound_pt:  string
  tip_b_label:  string
  tip_b_sound_en:  string
  tip_b_sound_pt:  string
  tip_c_label:  string
  tip_c_sound_en:  string
  tip_c_sound_pt:  string
  tip_d_label:  string
  tip_d_sound_en: string
  tip_d_sound_pt:  string
  answer_a_label: string
  answer_a_sound_en:  string
  answer_a_sound_pt:  string
  answer_b_label:  string
  answer_b_sound_en:  string
  answer_b_sound_pt:  string
  answer_c_label: string
  answer_c_sound_en:  string
  answer_c_sound_pt:  string
  answer_d_label:  string
  answer_d_sound_en:  string
  answer_d_sound_pt:  string
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function MagicBox() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const { handleTimer } = useGameplay()
  const router = useHistory()
  const { socket } = useSockets();
  const [lang, setLang] = useState("ingles");
  const [colorRobot, setColorRobot] = useState("head_green.png");
  const [srcBox, setSrcBox] = useState("/assets/images/animations/box1.png");

  const [end, setEnd] = useState(false);
  const [sound, setSound] = useState<null | Howl>()
  const [badge, setBadge] = useState<string>("")
  const [displayIce, setDisplayIce] = useState(false)
  const [musica] = useState<Howl>(new Howl({
    src: ['/assets/music/somgame.mp3'],
    autoplay: false,
    loop: false,
    volume: sessionStorage.getItem("musica") ? Number(sessionStorage.getItem("musica")?.toString()) / 100 : 1
  }));

  const [heading, setHeading] = useState<IHeading>({
    label: 'What is inside the Magic Box?',
    sound_en: '',
    sound_pt: ''
  })

  const [question, setQuestion] = useState<Questions>({
    correct_answer: '',
    question_image: '',
    tip_a_label: '',
    tip_a_sound_en: '',
    tip_a_sound_pt: '',
    tip_b_label: '',
    tip_b_sound_en: '',
    tip_b_sound_pt: '',
    tip_c_label: '',
    tip_c_sound_en: '',
    tip_c_sound_pt: '',
    tip_d_label: '',
    tip_d_sound_en: '',
    tip_d_sound_pt: '',
    answer_a_label: '',
    answer_a_sound_en: '',
    answer_a_sound_pt: '',
    answer_b_label: '',
    answer_b_sound_en: '',
    answer_b_sound_pt: '',
    answer_c_label: '',
    answer_c_sound_en: '',
    answer_c_sound_pt: '',
    answer_d_label: '',
    answer_d_sound_en: '',
    answer_d_sound_pt: '',
  })


  const setSoundUrl = useCallback((urlPt, urlIngles) => {
    const url = (lang === "portugues") ? urlPt : urlIngles;
    setSound(() => {
      return new Howl({
        src: [`${process.env.REACT_APP_API + url}`],
        volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1,
        html5: true,
      })
    });

  }, [lang])

  useEffect(() => {
    
    sessionStorage.setItem("urlGarra1", `${"/assets/images/garra.png"}?${global.Date.now()}`);
    sessionStorage.setItem("urlGarra2", `${"/assets/images/garra.png"}?${global.Date.now()}`);
    sessionStorage.setItem("urlGarra3", `${"/assets/images/garra.png"}?${global.Date.now()}`);
    sessionStorage.setItem("urlGarra4", `${"/assets/images/garra.png"}?${global.Date.now()}`);
    musica.play();
    
  

    if (sessionStorage.getItem("roleta")) {
  
      if(sessionStorage.getItem("color") !== "teacher") {        
    
      if(sessionStorage.getItem("TeamFreezed") === sessionStorage.getItem("color")) {
        setDisplayIce(true);
        setBadge("/assets/images/roleta/ice.png");
        sessionStorage.removeItem("TeamFreezed");
      } else {
        const roleta = JSON.parse(sessionStorage.getItem("roleta")!.toString());      
        const index = roleta.findIndex((o:any) => { return o.team === sessionStorage.getItem("color") } );
        const timeRoleta = index  > -1;
        const power = roleta.findIndex((o:any) => { return o.power ===  7 } );
        if(timeRoleta || power > -1) {
          sessionStorage.removeItem("escudo");
        }
        if(index !== -1)  {
            
          if (timeRoleta && roleta[index].power === 1) {         
            setBadge("/assets/images/roleta/block_yellow.png")
          } else if (timeRoleta && roleta[index].power === 2) {        
            setBadge("/assets/images/roleta/hidden2.png")
          } else if (timeRoleta && roleta[index].power === 3) {
            sessionStorage.setItem("escudo", "true");
            setBadge("/assets/images/roleta/shield.png");
          } else if (timeRoleta && roleta[index].power === 4) {        
            setBadge("/assets/images/roleta/block_red.png")
          } else if (timeRoleta && roleta[index].power === 5) {        
            setBadge("/assets/images/roleta/hidden.png")
          } else if (timeRoleta && roleta[index].power === 6) {        
            setBadge("/assets/images/roleta/block_green.png")
          } else if (sessionStorage.getItem("TeamFreezed") === sessionStorage.getItem("color") && roleta[index].power === 7) {        
            setDisplayIce(true);
            setBadge("/assets/images/roleta/ice.png");
            sessionStorage.removeItem("TeamFreezed");          
          } else if (timeRoleta && roleta[index].power === 8) {      
            setBadge("/assets/images/roleta/teacher.png") 
          }
  
        }
       
      }


      setTimeout(() => {
          sessionStorage.removeItem("roleta");      
      }, 1000);

    }


  }

  if(sessionStorage.getItem("escudo") === "true") {
    setBadge("/assets/images/roleta/shield.png");
  }

    return function fechar() {
      musica?.stop();
    }  
  }, [])



  const changeLanguageCallBack = useCallback(() => {

    if (lang === "ingles") {
      setLang("portugues");
    } else {
      setLang("ingles");
    }


  }, [lang, heading])

  useEffect(() => {
    setSoundUrl(heading.sound_pt, heading.sound_en);
  }, [lang])

  const handleChangeHeading = useCallback((data: IHeading) => {

    gsap
      .timeline()
      .fromTo(
        '.heading',
        {
          autoAlpha: 1,
          scale: 1,
        },
        {
          autoAlpha: 0,
          scale: 0,
          ease: Back.easeOut.config(1.2),
          onComplete: () => {
            setHeading(data)
          },
        }
      )
      .fromTo(
        '.heading',
        {
          autoAlpha: 0,
          scale: 1.5,
        },
        {
          autoAlpha: 1,
          scale: 1,
          ease: Back.easeOut.config(1.2),
        }
      )

    setSoundUrl(data.sound_pt, data.sound_en);


  }, [])

  useEffect(() => {

    socket.on("next-mistery-box", (body) => {

       if (!end) {
         setSrcBox("/assets/images/animations/box_tremendo.png");
         gsap
           .timeline()
           .fromTo(
             '.picture',
             { autoAlpha: 1, scale: 1, y: 0 },
             { autoAlpha: 1, scale: 1, y: 0, duration: 2 }
           )
 
         setTimeout(() => {
           setSrcBox("/assets/images/animations/box_abrindo.png");  
         }, 2000);
         
 
         gsap
           .timeline()
           .fromTo(
             '.picture',
             { autoAlpha: 1, scale: 1, y: 0, delay: 2  },
             { autoAlpha: 1, scale: 1, y: 150, duration: 1, delay: 2 }
           )
           .fromTo(
             '.question_image',
             { autoAlpha: 0, scale: 0, y: 150  },
             { autoAlpha: 1, scale: 1, maxHeight: "30vh", maxWidth: "30vw", y: 0, duration: 1 },
           )
           
       } if (!end) {
         setSrcBox("/assets/images/animations/box_tremendo.png");
         gsap
           .timeline()
           .fromTo(
             '.picture',
             { autoAlpha: 1, scale: 1, y: 0 },
             { autoAlpha: 1, scale: 1, y: 0, duration: 2 }
           )
 
         setTimeout(() => {
           setSrcBox("/assets/images/animations/box_abrindo.png");  
         }, 2000);
         
         const yInicial = windowDimensions.height > 768 ? 0 : 0;
         let yFinal = windowDimensions.height > 768 ? -500 : -400;
         yFinal = windowDimensions.height < 420 ? -200 : yFinal;
         gsap
           .timeline()
           .fromTo(
             '.picture',
             { autoAlpha: 1, scale: 1, y: 0, delay: 2  },
             { autoAlpha: 1, scale: 1, y: 150, duration: 1, delay: 2 }
           )
           .fromTo(
             '.question_image',
             { autoAlpha: 0, scale: 0, y: yInicial, zIndex: 10  },
             { autoAlpha: 1, scale: 1, y: yFinal, duration: 1, zIndex: 10  },
           )
           
       }
       setEnd(false); 
    
   });


 }, [end])

  /*
  useEffect(() => {    
    if(question.question_sound_en !== "") {
      setSound(() => {
        return new Howl({
          src: [`${process.env.REACT_APP_API + ((lang === "ingles") ? question.question_sound_en : question.question_sound_pt) }`],
          volume: 1,
          html5: true,        
        })
      })
    } else {
      setSound(null);
    }    
  }, [question.question_sound_en, question.question_sound_pt, lang]); */

  useEffect(() => {


      const questionTemp = JSON.parse(sessionStorage.getItem("question-game")!) as Questions;          
      sessionStorage.setItem("correct_answer", questionTemp.correct_answer);
      setQuestion(questionTemp);

      const timer = 10000;

      setTimeout(() => {
       
        if (questionTemp.tip_a_label === '') {
          return
        }
        handleChangeHeading({
          label: questionTemp.tip_a_label,
          sound_en: questionTemp.tip_a_sound_en,
          sound_pt: questionTemp.tip_a_sound_pt
        })

        setTimeout(() => {
          if (questionTemp.tip_b_label === '') {
            handleChangeHeading({
              label: 'What is inside the Magic Box?',
              sound_en: '',
              sound_pt: ''
            })
            return
          }
          handleChangeHeading({
            label: questionTemp.tip_b_label,
            sound_en: questionTemp.tip_b_sound_en,
            sound_pt: questionTemp.tip_b_sound_pt
          })

          setTimeout(() => {
            if (questionTemp.tip_c_label === '') {
              handleChangeHeading({
                label: 'What is inside the Magic Box?',
                sound_en: '',
                sound_pt: ''
              })
              return
            }
            handleChangeHeading({
              label: questionTemp.tip_c_label,
              sound_en: questionTemp.tip_c_sound_en,
              sound_pt: questionTemp.tip_c_sound_pt
            })

            setTimeout(() => {
              if (question.tip_d_label === '') {
                handleChangeHeading({
                  label: 'What is inside the Magic Box?',
                  sound_en: '',
                  sound_pt: ''
                })
                return
              }
              handleChangeHeading({
                label: questionTemp.tip_d_label,
                sound_en: questionTemp.tip_d_sound_en,
                sound_pt: questionTemp.tip_d_sound_pt
              })

              setTimeout(() => {
                handleChangeHeading({
                  label: 'What is inside the Magic Box?',
                  sound_en: '',
                  sound_pt: ''
                })
              }, timer)
            }, timer)
          }, timer)
        }, timer)
      }, 5000)


    if (sessionStorage.getItem("color") === "green") {
      setColorRobot("head_green.png");
    } else if (sessionStorage.getItem("color") === "yellow") {
      setColorRobot("head_yellow.png");
    } else if (sessionStorage.getItem("color") === "red") {
      setColorRobot("head_red.png");
    } else if (sessionStorage.getItem("color") === "blue") {
      setColorRobot("head_blue.png");
    } else if (sessionStorage.getItem("color") === "teacher") {
      setColorRobot("teacher");
    }


  }, [])

  useEffect(() => {
    gsap
      .timeline({
        delay: 1,
        onComplete: handleTimer,
      })
      .fromTo(
        '.question_image',
        { autoAlpha: 0, scale: 1 },
        { autoAlpha: 0, scale: 0, y: 250 }
      )
      .fromTo(
        '.picture',
        { autoAlpha: 0 },
        { autoAlpha: 1, ease: Power0.easeOut }
      )
      .fromTo(
        '.heading',
        { autoAlpha: 0, scale: 1.5 },
        { autoAlpha: 1, scale: 1.0, ease: Back.easeOut.config(1.2) }
      )
      .fromTo(
        '.control',
        { autoAlpha: 0, y: 300, height: 0 },
        { autoAlpha: 1, y: 0, height: 'auto', ease: Back.easeOut.config(1.2) }
      )
  }, [])

  return (
    <>
      <img src={sessionStorage.getItem("urlGarra1") + ''} alt="" style={{display: "none"}} />
      <img src={sessionStorage.getItem("urlGarra2") + ''} alt="" style={{display: "none"}} />
      <img src={sessionStorage.getItem("urlGarra3") + ''} alt="" style={{display: "none"}} />
      <img src={sessionStorage.getItem("urlGarra4") + ''} alt="" style={{display: "none"}} />
      <div className="missingObject" style={displayIce ? { display: "block" } : { display: "none" }}>
        <img src="/assets/images/ice_frame.png" alt="" className='img-100' />
      </div>
      <div className="row" style={{ maxWidth: "98%", height: "100%" }}>
        <div className="group-robot col-12 center-flex" style={{ width: "100%"}}>

          <div className='group-robot col-12 center-flex' style={{ height: "100%"}}>
           {badge !== "" && <img src={badge} className='badge-robot' alt="" />}  
            {(colorRobot === "head_green.png") && (<img src='/assets/static/robot/head_green.png' className="cabeca-game" alt="" />)}
            {(colorRobot === "head_red.png") && (<img src='/assets/robot/head_red.png' className="cabeca-game" alt="" />)}
            {(colorRobot === "head_yellow.png") && (<img src='/assets/static/robot/head_yellow.png' className="cabeca-game" alt="" />)}
            {(colorRobot === "head_blue.png") && (<img src='/assets/static/robot/head_blue.png' className="cabeca-game" alt="" />)}
            {(colorRobot === "teacher") && (<img src='/assets/images/teacher.png' className="cabeca-game" alt="" />)}

         
            <span className='name-robot'> { colorRobot === "teacher" ? sessionStorage.getItem("name") : sessionStorage.getItem("nameTeam")} </span>

          </div>

        </div>
        <div className="col-1 center-flex" style={{ width: "15%" }} >
          <div className='center-flex'>
            {(lang === "ingles") ? <img src='/assets/static/icons/ingles.png' alt="" className='changeLanguage center-flex' onClick={changeLanguageCallBack} /> :
              <img src='/assets/static/icons/portugues.png' alt="" className='changeLanguage center-flex' onClick={changeLanguageCallBack} />
            }</div>
        </div>

        <div className="col-10" style={{ width: "70%" }}>

          <Container className="main container">
            <View>
              <div className="row container-magic w-100">

                <div className="col-12">
                  <Content style={{zIndex: 0}}>

                    {heading.label !== 'What is inside the Magic Box?' && (
                      <div className='btn-play' style={{zIndex: 0}}>
                        {question.answer_a_sound_en && (<img src='/assets/static/buttons/play.png' className='button-play' onClick={() => sound!.play()} />)}
                      </div>)}

                    <Text style={{zIndex: 0}} className={`heading heading-3 show hide-md ${heading.label === 'What is inside the Magic Box?' ? 'label-question' : 'label-text'}`}>
                      {heading.label}
                    </Text>
                  </Content>
                </div>                
                  <div className="col-6 text-center center-flex">
                      <div className='center-flex box-group-2' > 
                        <img src={srcBox}  className='picture-box-magic text-center box-magic picture center-flex box-group' alt=""  />
                        <img src={process.env.REACT_APP_API + question.question_image} className='text-center box-magic question_image center-flex picture-box'  alt=""  />
                        {/* <img src="/assets/images/box-gain.jpg" className='text-center box-magic question_image center-flex picture-box'  alt=""  />  */}
                      </div>
                  </div>
                  <div className="col-6 text-end center-flex" style={{height: "100%"}}>
                    <Controls game='magic' colorRobot={colorRobot} col={6} data={question} lang={lang} />
              
                  </div>
                
              </div>
            </View>

          </Container>
        </div>
        <div className="col-1" style={{ width: "15%" }} >
          <div style={{ marginLeft: "4vw" }}>
            <Timer />
          </div>

        </div>
      </div>
    </>
  )
}
