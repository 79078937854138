import View from '../View'
import Scaled from '../Scaled'

import { Container } from './styles'
import { Howl } from 'howler';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function Congratulation() {

  const router = useHistory()
  const [showPoint, setShowPoint] = useState([false,false,false,false,false,false,false,false])
  const [times, setTimes] = useState(["","","","","","","","",""])



  const [sound] = useState<Howl>(new Howl({
    src: ['/assets/music/triunfo.mp3'],
    autoplay: false,
    loop: true,
    volume: sessionStorage.getItem("musica") ? Number(sessionStorage.getItem("musica")?.toString()) / 100 : 1
  }));

  const [effect1] = useState<Howl>(new Howl({
    src: ['/assets/music/congratulations.mp3'],
    autoplay: false,
    loop: false,
    volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1
  }));

  const [effect2] = useState<Howl>(new Howl({
    src: ['/assets/music/aplausos.mp3'],
    autoplay: false,
    loop: true,
    volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1
  }));

  useEffect(() => {
    
    sound.play();
    effect1.play();
    effect2.play();

    const pontuacao = JSON.parse(sessionStorage.getItem("pontuacao")!.toString());

    // const pontos = JSON.parse(sessionStorage.getItem("pontuacao")!.toString()).sort();
    const pontos = pontuacao.pontos.sort(function(a : any, b :any) {
      return a.pontos - b.pontos;
    });

    console.log(pontuacao);

    const time = ["","","","","","","",""]
    if(pontos[3].pontos === pontos[2].pontos && pontos[2].pontos === pontos[1].pontos 
      && pontos[1].pontos === pontos[0].pontos) {
      time[0] = pontos[3].time;
      time[1] = pontos[2].time;
      time[2] = pontos[1].time;
      time[3] = pontos[0].time;
      setTimes(time);
      setShowPoint([pontos[3].visivel,pontos[2].visivel,pontos[1].visivel,pontos[0].visivel,false,false,false,false]);
    } else if(pontos[3].pontos === pontos[2].pontos && pontos[2].pontos === pontos[1].pontos) {
      time[0] = pontos[3].time;
      time[1] = pontos[2].time;
      time[2] = pontos[1].time;
      time[7] = pontos[0].time;
      setTimes(time);
      setShowPoint([pontos[3].visivel,pontos[2].visivel,pontos[1].visivel,false,false,false,false,pontos[0].visivel]);
    } else if(pontos[3].pontos === pontos[2].pontos) {
      time[0] = pontos[3].time;
      time[1] = pontos[2].time;
      time[6] = pontos[1].time;
      time[7] = pontos[0].time;
      setTimes(time);
      setShowPoint([pontos[3].visivel,pontos[2].visivel,false,false,false,false,pontos[1].visivel,pontos[0].visivel]);
    } else if(pontos[3].pontos !== pontos[2].pontos) {
      time[0] = pontos[3].time;
      time[5] = pontos[2].time;
      time[6] = pontos[1].time;
      time[7] = pontos[0].time;
      setTimes(time);
      setShowPoint([pontos[3].visivel,false,false,false,false,pontos[2].visivel,pontos[1].visivel,pontos[0].visivel]);
    } else {
      time[4] = pontos[3].time;
      time[5] = pontos[2].time;
      time[6] = pontos[1].time;
      time[7] = pontos[0].time;
      setShowPoint([false,false,false,false,pontos[3].visivel,pontos[3].visivel,pontos[1].visivel,pontos[0].visivel]);
    }

    // azul amarelo verde vermelho
    
    setTimeout(() => {
      router.push("/");
      document.location.reload();
    }, 30000); 
    

    return function limpar () {      
      sound.stop();
      effect1.stop();
      effect2.stop();
    };
  }, [])

  return (
    <>
      <div className="missingObject">
        <img src="/assets/images/congratulation/confete.png" alt="" className='img-100' />
      </div>

      <Container>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-1" style={{ width: "10%" }}></div>

          <div className="col-10" style={{ width: "80%" }}>
            <img src="/assets/images/congratulation.jpg" alt="" className='congratulation' />
          {/* {showPoint[0] &&  <img src={`/assets/images/congratulation/${times[0]}.png`} alt="" className='img-fluid congratulation-img1' /> }
          {showPoint[1] &&  <img src={`/assets/images/congratulation/${times[1]}.png`} alt="" className='img-fluid congratulation-img2' /> }
             {showPoint[2] && <img src={`/assets/images/congratulation/${times[2]}.png`} alt="" className='img-fluid congratulation-img3' />}
             {showPoint[3] &&  <img src={`/assets/images/congratulation/${times[3]}.png`} alt="" className='img-fluid congratulation-img4' />}
             {showPoint[4] &&  <img src={`/assets/images/congratulation/${times[4]}.png`} alt="" className='img-fluid congratulation-img5' />}
             {showPoint[5] &&  <img src={`/assets/images/congratulation/${times[5]}.png`} alt="" className='img-fluid congratulation-img6' /> }
             {showPoint[6] &&  <img src={`/assets/images/congratulation/${times[6]}.png`} alt="" className='img-fluid congratulation-img7' /> }
             {showPoint[7] && <img src={`/assets/images/congratulation/${times[7]}.png`} alt="" className='img-fluid congratulation-img8' /> } */}
            <div className='tronco-1'>
              {showPoint[0] ? <img src={`/assets/images/congratulation/${times[0]}.png`} alt="" className='img-fluid new-congratulation-img1' /> : ''}
              {showPoint[1] ? <img src={`/assets/images/congratulation/${times[1]}.png`} alt="" className='img-fluid new-congratulation-img2' /> : ''}
              {showPoint[2] ? <img src={`/assets/images/congratulation/${times[2]}.png`} alt="" className='img-fluid new-congratulation-img3' /> : ''}
              {showPoint[3] ? <img src={`/assets/images/congratulation/${times[3]}.png`} alt="" className='img-fluid new-congratulation-img4' /> : ''}
            </div>
            <div className='tronco-2'>
              {showPoint[4] ? <img src={`/assets/images/congratulation/${times[4]}.png`} alt="" className='img-fluid new-congratulation-img5' /> : ''}
              {showPoint[5] ? <img src={`/assets/images/congratulation/${times[5]}.png`} alt="" className='img-fluid new-congratulation-img6' /> : ''}
              {showPoint[6] ? <img src={`/assets/images/congratulation/${times[6]}.png`} alt="" className='img-fluid new-congratulation-img7' /> : ''}
              {showPoint[7] ? <img src={`/assets/images/congratulation/${times[7]}.png`} alt="" className='img-fluid new-congratulation-img8' /> : ''}
            </div>
          </div>
          <div className="col-1" style={{ width: "10%" }}></div>
        </div>
      </Container>
    </>
  )
}
