import { useCallback, useEffect, useRef, useState } from 'react'
import gsap, { Back, Power0 } from 'gsap'

import View from '../View'
import Controls from '../Controls'

import box from '../../static/img.png'

import { useGameplay } from '../../hook/useGameplay'
import { Container, Content, Text, Image } from './styles'
import Timer from '../Timer'
import { useSockets } from '../../context/socket.context'
import { Howl } from 'howler'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


interface IQuestion {
  correct_answer: string
  question_image: string
  hidden_image: string
  answer_a_label: string
  answer_a_image: string
  answer_a_sound_en: string
  answer_a_sound_pt: string
  answer_b_label: string
  answer_b_image: string
  answer_b_sound_en: string
  answer_b_sound_pt: string
  answer_c_label: string
  answer_c_image: string
  answer_c_sound_en: string
  answer_c_sound_pt: string
  answer_d_label: string
  answer_d_image: string
  answer_d_sound_en: string
  answer_d_sound_pt: string
}
export default function MissingObject() {
  const { handleTimer } = useGameplay()
  const [lang, setLang] = useState("ingles");
  const { socket } = useSockets();
  const [srcBox, setSrcBox] = useState("/assets/images/blank.png");
  const [blackScreen, setBlackScreen] = useState(false);
  const [srcQuestion, setSrcQuestion] = useState("/assets/images/blank.png");
  const [display, setDisplay] = useState(true);
  const [badge, setBadge] = useState<string>("")
  const [displayIce, setDisplayIce] = useState(false)
  const [displayIMG, setDisplayIMG] = useState(false);

  const [sound, setSound] = useState<null | Howl>()
  const myRef = useRef()
  const [musica] = useState<Howl>(new Howl({
    src: ['/assets/music/somgame.mp3'],
    autoplay: false,
    loop: false,
    volume: sessionStorage.getItem("musica") ? Number(sessionStorage.getItem("musica")?.toString()) / 100 : 1
  }));

  const [question, setQuestion] = useState({
    correct_answer: '',
    question_image: '',
    hidden_image: '',
    answer_a_label: '',
    answer_a_image: '',
    answer_a_sound_en: '',
    answer_a_sound_pt: '',
    answer_b_label: '',
    answer_b_image: '',
    answer_b_sound_en: '',
    answer_b_sound_pt: '',
    answer_c_label: '',
    answer_c_image: '',
    answer_c_sound_en: '',
    answer_c_sound_pt: '',
    answer_d_label: '',
    answer_d_image: '',
    answer_d_sound_en: '',
    answer_d_sound_pt: ''
  });


  const [colorRobot, setColorRobot] = useState<string>("head_green.png")

  const changeLanguage = useCallback(() => {
    if (lang === "ingles") setLang("portugues"); else setLang("ingles")
  },
    [lang]
  )


  useEffect(() => {
    musica.play();


    sessionStorage.setItem("urlGarra1", `${"/assets/images/garra.png"}?${global.Date.now()}`);
    sessionStorage.setItem("urlGarra2", `${"/assets/images/garra.png"}?${global.Date.now()}`);
    sessionStorage.setItem("urlGarra3", `${"/assets/images/garra.png"}?${global.Date.now()}`);
    sessionStorage.setItem("urlGarra4", `${"/assets/images/garra.png"}?${global.Date.now()}`);
    
    

    if (sessionStorage.getItem("roleta")) {

      if(sessionStorage.getItem("color") !== "teacher") {        

      if(sessionStorage.getItem("TeamFreezed") === sessionStorage.getItem("color")) {
        setDisplayIce(true);
        setBadge("/assets/images/roleta/ice.png");
        sessionStorage.removeItem("TeamFreezed");
      } else {
        const roleta = JSON.parse(sessionStorage.getItem("roleta")!.toString());      
        const index = roleta.findIndex((o:any) => { return o.team === sessionStorage.getItem("color") } );
        const timeRoleta = index  > -1;
        const power = roleta.findIndex((o:any) => { return o.power ===  7 } );
        if(timeRoleta || power > -1) {
          sessionStorage.removeItem("escudo");
        }
        if(index !== -1) {
  
          if (timeRoleta && roleta[index].power === 1) {        
            setBadge("/assets/images/roleta/block_yellow.png")
          } else if (timeRoleta && roleta[index].power === 2) {        
            setBadge("/assets/images/roleta/hidden2.png")
          } else if (timeRoleta && roleta[index].power === 3) {
            sessionStorage.setItem("escudo", "true");
            setBadge("/assets/images/roleta/shield.png");
          } else if (timeRoleta && roleta[index].power === 4) {        
            setBadge("/assets/images/roleta/block_red.png")
          } else if (timeRoleta && roleta[index].power === 5) {        
            setBadge("/assets/images/roleta/hidden.png")
          } else if (timeRoleta && roleta[index].power === 6) {        
            setBadge("/assets/images/roleta/block_green.png")
          } else if (sessionStorage.getItem("TeamFreezed") === sessionStorage.getItem("color") && roleta[index].power === 7) {        
            setDisplayIce(true);
            setBadge("/assets/images/roleta/ice.png");
            sessionStorage.removeItem("TeamFreezed");          
          } else if (timeRoleta && roleta[index].power === 8) {      
            setBadge("/assets/images/roleta/teacher.png")
          }
     
        }
      
      }

      if(sessionStorage.getItem("escudo") !== null) {
        setBadge("/assets/images/roleta/shield.png");
      }


      setTimeout(() => {
          sessionStorage.removeItem("roleta");   
      }, 1000);
    }


  }

    if (sessionStorage.getItem("color") === "green") {
      setColorRobot("head_green.png");
    } else if (sessionStorage.getItem("color") === "yellow") {
      setColorRobot("head_yellow.png");
    } else if (sessionStorage.getItem("color") === "red") {
      setColorRobot("head_red.png");
    } else if (sessionStorage.getItem("color") === "blue") {
      setColorRobot("head_blue.png");
    } else if (sessionStorage.getItem("color") === "teacher") {
      setColorRobot("teacher");
    }

    const questionTemp = JSON.parse(sessionStorage.getItem("question-game")!) as IQuestion;          
    sessionStorage.setItem("correct_answer", questionTemp.correct_answer);      
    setQuestion(questionTemp);
    setSrcQuestion(process.env.REACT_APP_API + questionTemp.hidden_image);    

    return function fechar() {
      musica?.stop();
    }  
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setSrcBox("/assets/images/animations/missing.png");
      setTimeout(() => {
        setBlackScreen(true);
      }, 1100);
      setTimeout(() => {
        setSrcQuestion(process.env.REACT_APP_API + question.question_image);
        gsap.timeline().fromTo(
          '#tempo',
          { autoAlpha: 0 },
          { autoAlpha: 1 }
        )
        setTimeout(() => {
          setBlackScreen(false);
        }, 5200);
        setTimeout(() => {
          setDisplay(false);
          setSrcBox("/assets/images/blank.png");  
        }, 6000);
        
      }, 2000);
    }, 6000);

    
  }, [question])

  useEffect(() => {  
    const altura = getWindowDimensions().height > 768 ? 10 : -30;
    gsap
      .timeline({
        delay: 1,
        onComplete: handleTimer,
      })
      .fromTo(
        '#tempo',
        { autoAlpha: 0 },
        { autoAlpha: 0 }
      )
      .fromTo(
        '.picture',
        { autoAlpha: 1, scale: 2, marginTop: "25vh", maxWidth: "50%"},
        { autoAlpha: 1, scale: 1, ease: Power0.easeOut, delay: 12, marginTop: "10vh" }
      )
      .fromTo(
        '.heading',
        { autoAlpha: 0, scale: 1.5 },
        { autoAlpha: 1, scale: 1.0, ease: Back.easeOut.config(1.2) }
      ).fromTo(
        '.button-play',
        { autoAlpha: 0, scale: 1.5 },
        { autoAlpha: 1, scale: 1.0, ease: Back.easeOut.config(1.2) }
      )
      .fromTo(
        '.control',
        { autoAlpha: 0, y: 300, height: 0 },
        { autoAlpha: 1, y: altura, height: 'auto', ease: Back.easeOut.config(1.2) }
      )
  }, [])

  
  useEffect(() => {

    setSound(() => {
      return new Howl({
        src: [`${((lang === "ingles") ? "/assets/music/obj_eng.mp3" : "/assets/music/obj_port.mp3")}`],
        volume: sessionStorage.getItem("som") ? Number(sessionStorage.getItem("som")?.toString()) / 100 : 1,
        html5: true,
      })
    })
 
}, [lang]);

  return (
    <>
    
      <img src={sessionStorage.getItem("urlGarra1") + ''} alt="" style={{display: "none"}} />
      <img src={sessionStorage.getItem("urlGarra2") + ''} alt="" style={{display: "none"}} />
      <img src={sessionStorage.getItem("urlGarra3") + ''} alt="" style={{display: "none"}} />
      <img src={sessionStorage.getItem("urlGarra4") + ''} alt="" style={{display: "none"}} />
      
      <div className="missingObject" style={displayIce ? {display:"block"} : {display: "none"}}>
        <img src="/assets/images/ice_frame.png" alt="" className='img-100' />
      </div>

      <div className={"missingObject" + (blackScreen === true ? " black-screen" : '')} style={display ? {display:"block"} : {display: "none"}}>
        <img src={srcBox} alt="" className={srcBox === "/assets/images/animations/missing.png" ? "robo-img" : 'img-100'} />
      </div>

      <div className={"img-modal " + (displayIMG ? "active" : "disabled")} onClick={() => setDisplayIMG(!displayIMG)}>
        <img src={srcQuestion} alt="" />
      </div>

      <div className="row" style={{ maxWidth: "98%", height: "100%" }}>

        <div className="group-robot col-12 center-flex" style={{ width: "100%" }}>
        <div className='group-robot center-flex'>         
        {badge !== "" && <img  src={badge} className='badge-robot' alt="" />   }
          {(colorRobot === "head_green.png") && (<img src='/assets/static/robot/head_green.png' className="cabeca-game" alt="" />)}
          {(colorRobot === "head_red.png") && (<img src='/assets/static/robot/head_red.png' className="cabeca-game"  alt="" />)}
          {(colorRobot === "head_yellow.png") && (<img src= '/assets/static/robot/head_yellow.png' className="cabeca-game" alt="" />)}
          {(colorRobot === "head_blue.png") && (<img src= '/assets/static/robot/head_blue.png' className="cabeca-game" alt="" />)}      
          {(colorRobot === "teacher") && (<img src='/assets/images/teacher.png' className="cabeca-game"  alt="" />)}         


          <span className='name-robot'> { colorRobot === "teacher" ? sessionStorage.getItem("name") : sessionStorage.getItem("nameTeam")} </span> 
        </div>
        </div>
        <div className="col-1 center-flex" style={{ width: "15%" }} >
          <div className='center-flex'>
            {(lang === "ingles") ? <img src= '/assets/static/icons/ingles.png' alt="" className='changeLanguage center-flex' onClick={changeLanguage} /> :
              <img src='/assets/static/icons/portugues.png' alt="" className='changeLanguage center-flex' onClick={changeLanguage} />
            }</div>
        </div>

        <div className="col-10" style={{ width: "70%" }}>

          <Container className="main container" >
            <View>
              <div className="row no-gutters">
                <div className="col col-12 col-lm-6">
                  <Content>
                  <div className='btn-play'>
                       <img src='/assets/static/buttons/play.png' className='button-play' onClick={() => sound!.play()}  />
                      </div>
                  <div className='pergunta' style={{paddingRight: "50px"}}>
                    <Text className="heading heading-3" >
                      Which object is missing?
                    </Text>
                  </div>
                    <Image className="picture img-max" src={srcQuestion} onClick={() => setDisplayIMG(!displayIMG)} />

                  </Content>
                </div>
                <div className="control col col-12 col-lm-6 d-flex align-items-center justify-content-center adjust">
                  <Controls colorRobot={colorRobot} col={3} data={question} lang={lang} game="missing" />
                </div>
              </div>
            </View>
          </Container>
        </div>
        <div className="col-1" style={{ width: "15%" }} id="tempo" >
                  
        <div style={{marginLeft: "4vw"}}>
                <Timer />
            </div>
        </div>
      </div>
    </>
  )
}
