import styled from 'styled-components'

import bg from '../../../static/bg.png'

export const Container = styled.div`
  height: 100%;
  .row {
    min-height: 100%;
    .carton {
      background-image: url(${bg});
      background-position: center;
      background-size: 120%;
      min-height: 100%;
      border: 3px solid #471f1f;
      border-radius: 0.625rem;
      &.carton-green {
        background-color: #27d323;
      }
      &.carton-blue {
        background-color: #1dacf5;
      }
      &.carton-yellow {
        background-color: #ffcb00;
      }
      &.carton-red {
        background-color: #ff4360;
      }
      h2 {
        color: #ffffff;
      }
    }
  }
  @media (max-width: 576px) {
    .footer {
      .list-inline {
        width: 100%;
        .list-item {
          flex: 1;
          .button {
            width: 100%;
          }
        }
      }
    }
  }
`


export const ContainerInner = styled.div`
  height: 70%;
  border: 7px solid #A054A0 !important;
  border-radius: 32px !important;
  padding: 32px !important;
  /* @media (max-width: 768px) and (orientation: landscape) {
    height: 100%;
    border-width: 4px !important;
    border-radius: 10px !important;
    padding: 0.5rem !important;
  } */
  /* @media (max-width: 576px) { */
  @media (max-height: 768px) {
    height: 80%;
    margin-bottom: 50px;
  }
  @media (max-height: 420px) {
    height: 70vh !important;
  }
  @media (max-width: 768px) {
    height: 100%;
    border-width: 4px !important;
    border-radius: 15px !important;
    padding: 6px !important;
  }
  &.is-scroll > div {
    border-radius: 10px !important;
  }
`