import styled from 'styled-components'

export const Container = styled.div`
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100% !important; 
  width: calc(100% + 10px);
  flex: 1;
`
