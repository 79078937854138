import { useGameplay } from '../../hook/useGameplay'
import { Container, Arrow, Text } from './styles'

export default function Timer() {
  const { timerText } = useGameplay()

  return (
    <Container id="timer" className='timer' >
      <Arrow id="timer-arrow" />
      <Text className="text-timer">{timerText}</Text>
    </Container>
  )
}
