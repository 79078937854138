import { useState, useCallback, ChangeEvent, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import View from '../../../components/View'


import { Container, PrivateRoom, CheckAndUncheck, RobotCard } from './styles'
import { api } from '../../../services/api'
import { io } from 'socket.io-client'
import { useSockets } from '../../../context/socket.context'
import { Howl } from 'howler'
import ReactTooltip from 'react-tooltip'
const InputMask = require('react-input-mask');
type IGroupType = 'green' | 'blue' | 'yellow' | 'red'

interface IRoom {
  match_id: string
}
interface IFormCreateRoom {
  name: string
  closed: boolean
  duration: string
  timer: string
  wheel: boolean
  TeamBlueName: string
  TeamGreenName: string
  TeamOrangeName: string
  TeamRedName: string
  units: Array<string>
  games: Array<string>
  categories: Array<string>
  groups: Array<string>
}

export default function CreateRooms() {

  const { socket, roomId, rooms } = useSockets();


  const [labelCheckBook, setLabelCheckBook] = useState("Check all");
  const [labelCheckUnit, setLabelCheckUnit] = useState("Check all");
  const [labelCheckGames, setLabelCheckGames] = useState("Check all");
  const [labelCheckGroups, setLabelCheckGroups] = useState("Check all");
  const [labelCreateRoom, setLabelCreateRoom] = useState("Create room");

  const [robotBlue, setRobotBlue] = useState(false);
  const [robotYellow, setRobotYellow] = useState(false);
  const [robotRed, setRobotRed] = useState(false);
  const [robotGreen, setRobotGreen] = useState(false);

  const [validForm, setvalidForm] = useState(false);

  const [sound, setSound] = useState<Howl>(new Howl({
    src: ['/assets/music/config.mp3'],
    autoplay: false,
    loop: true,
    volume: sessionStorage.getItem("musica") ? Number(sessionStorage.getItem("musica")?.toString()) / 100 : 1
  }));

  const [unit, setUnits] = useState({
    quiz: [],
    misterybox: [],
    hiddenobject: []
  });




  const { register, handleSubmit, setValue, getValues, watch } =
    useForm<IFormCreateRoom>({
      defaultValues: {
        name: '',
        closed: false,
        wheel: false,
        units: [],
        games: [],
        timer: '30',
        duration: '30',
        categories: [],
        groups: ['blue', 'green', 'red', 'yellow'],
        TeamGreenName: '',
        TeamRedName: '',
        TeamOrangeName: '',
        TeamBlueName: ''
      },
    })

  const watchFields = watch(["categories"]);

  function handleKeyPress(e:any) {
    const key = e.key;
    const regex = /^[a-zA-Z0-9]+$/;
    if(!regex.test(key) ) {
        e.preventDefault();
        alert("Special characters not allowed");
    }
    
}

  useEffect(() => {
    const subscription = watch((value:any, { name, type }) => {

      if (name === "categories") {
        api
          .post("/units", value.categories)
          .then((response: any) => {
            const games = getValues("games");
            const hidden_objectIndex = games.indexOf("hidden_object");
            const misteryboxIndex = games.indexOf("mistery_box");
            const quizIndex = games.indexOf("quiz");
            if (response.data.hiddenobject.length === 0 && hidden_objectIndex > -1) games.splice(hidden_objectIndex);
            if (response.data.misterybox.length === 0 && misteryboxIndex > -1) games.splice(hidden_objectIndex);
            if (response.data.quiz.length === 0 && quizIndex > -1) games.splice(hidden_objectIndex);

            setUnits(response.data);
            setValue('games', games);
          })
          .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
          });
      }

    })
  }, [watch, unit])



  const router = useHistory()

  const handleSwitchWheel = useCallback(() => {
    const value = getValues('wheel')
    setValue('wheel', !value)
  }, [])

  const handleCheckAll = useCallback(() => {
    if (getValues('units').length < 8) {
      setValue('units', ['Unit 1', 'Unit 2', 'Unit 3', 'Unit 4', 'Unit 5', 'Unit 6', 'Unit 7', 'Unit 8',])
    } else {
      setValue('units', [])
    }
  }, [])

  const checkAllBook = useCallback(() => {
    if (getValues('categories').length < 4) {
      setValue('categories', ['Blue', 'Yellow', 'Red', 'Event']);
    } else {
      setValue('categories', []);
    }
  }, []);

  const checkAllGames = useCallback(() => {

    if (getValues('games').length < 3) {
      const games = [];
      if (unit.hiddenobject.length > 0) games.push("hidden_object");
      if (unit.misterybox.length > 0) games.push("mistery_box");
      if (unit.quiz.length > 0) games.push("quiz");
      setValue('games', games);
    } else {
      setValue('games', []);
    }
  }, [unit]);

  function checkLabelCategories() {
    if (getValues('categories').length === 4) {
      setLabelCheckBook("Uncheck all");
    } else {
      setLabelCheckBook("Check all");
    }
  }

  function checkLabelGames() {
    if (getValues('games').length === 3) {
      setLabelCheckGames("Uncheck all");
    } else {
      setLabelCheckGames("Check all");
    }
  }

  useEffect(() => {

    return function limpar() {
      sound.stop();
    };
  }, []);

  function checkLabelUnit() {
    if (getValues('units').length === 8) {
      setLabelCheckUnit("Uncheck all");
    } else {
      setLabelCheckUnit("Check all");
    }
  }

  

  useEffect(() => {
    const subscription = watch(() => {
      setRobotBlue(getValues("groups").includes("blue"));
      setRobotYellow(getValues("groups").includes("yellow"));
      setRobotRed(getValues("groups").includes("red"));
      setRobotGreen(getValues("groups").includes("green"));
      checkLabelCategories()
      checkLabelUnit()
      checkLabelGames()

      if (getValues("name").length > 0 && getValues("games").length > 0 && getValues("units").length > 0) {
        setvalidForm(true);

        let quantidade = 0;
        if (getValues("TeamGreenName").length > 0) quantidade += 1;
        if (getValues("TeamRedName").length > 0) quantidade += 1;
        if (getValues("TeamOrangeName").length > 0) quantidade += 1;
        if (getValues("TeamBlueName").length > 0) quantidade += 1;

        if (quantidade !== 2 && quantidade !== 4) setvalidForm(false);

      } else {
        setvalidForm(false);
      }
    });
    sound.play();
  }, [watch])

  const handleSocketConnection = useCallback(() => {

    setLabelCreateRoom("Creating room...");

    const body = {
      NameRoom: getValues("name"),
      TeamGreenName: getValues("TeamGreenName"),
      TeamBlueName: getValues("TeamBlueName"),
      TeamOrangeName: getValues("TeamOrangeName"),
      TeamRedName: getValues("TeamRedName"),
      timer: getValues("timer"),
      match: getValues("duration"),
      games: getValues("games"),
      categories: getValues("categories"),
      groups: getValues("groups"),
      wheel: getValues("wheel"),
      units: getValues("units")

     
    }

    socket.on("criando-sala", (body) => {
 
      if(body === "success")  {

        sessionStorage.setItem("nameRoom", getValues("name"));
        sessionStorage.setItem("tipo-usuario", "professor");
        sessionStorage.setItem("tipo-usuario", "professor");    
        router.replace("/rooms/" + getValues("name"))
      }       else {
        alert(body);
        setLabelCreateRoom("Create room");
      }
    
    });

    setTimeout(() => {
      socket.emit("createRoom", body);    
    }, 1000);    

  }, [labelCreateRoom])


  function onChangeInput(event : any) {
    if(event.target.value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/)){
      alert("gdxgd");
    }     
  }
 
  return (

    <div className="div-main" >
      
      <div className='text-center' style={{ width: "15%",   justifyItems: "start",   justifyContent: "start" }}>
        <img src="/assets/images/buttons/back.png" alt="" onClick={router.goBack} className='btn-leave' style={{marginTop: "-65vh"}} />
      </div>

      <div style={{ width: "70%" }}>
        <Container className="main container " >

          <View title="Let’s start" scroll >
            <section>
              <h2 className="heading-3 text-uppercase heading-42">Room</h2>

              <div className="position-relative">
                <input
                  type="text"
                  className="field w-100"
                  id="nomesala"                
                  placeholder="Room name"
                  onKeyPress={(e) => handleKeyPress(e)}
                  {...register('name')}
                />
              </div>
            </section>

            <hr />

            <section>
              <div className="row">
                <div className="col-12 col-sm-8">
                  <div className="media">

                    <img src="/assets/static/duration.png" className="image" />
                    <div className="media-content">
                      <h2 className="heading-4 text-uppercase">Match Time</h2>

                      <div className='icon-inside'>
                        <InputMask
                          type="text"
                          className="field  w-100"
                          mask="99"
                          maskChar=""
                          placeholder="0"
                          {...register('duration')}

                        />
                        <span className='input-inner'>minutes</span>
                      </div>

                    </div>

                    <div className="media-content">
                      <h2 className="heading-4 text-uppercase">Time per Question</h2>
                      <div className='icon-inside'>
                        <InputMask
                          mask="999"
                          maskChar=""
                          className="field  w-100"
                          placeholder="0"
                          min="30"
                          max="120"
                          {...register('timer')}
                        />
                        <span className='input-inner'>seconds</span>
                      </div>

                    </div>
                  </div>

                  <hr className="hide show-sm" />
                </div>

                <div className="col-12 col-sm-4">
                  <div className="media">
                    <img src='/assets/static/wheel.png' className="image" />
                    <div className="media-content">
                      <h2 className="heading-3 text-uppercase heading-42">Wheel</h2>

                      <button
                        type="button"
                        className={`button button-block ${watch('wheel') ? `button-green` : `button-red`
                          }`}
                        onClick={handleSwitchWheel}
                      >
                        {watch('wheel') ? `ON` : `OFF`}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <hr />

            <section>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h2 className="heading-3 text-uppercase m-0 heading-42">Book</h2>
                <CheckAndUncheck className={`button ${(labelCheckBook === 'Check all') ? 'button-yellow' : 'button-gray'}`} onClick={checkAllBook} type="button">
                  {labelCheckBook}
                </CheckAndUncheck>
              </div>

              <div className="row">
                <div className="col-6 col-sm-3">
                  <label className="checkcad checked-blue">
                    <input
                      type="checkbox"
                      className="checkcad-input"
                      value="Blue"
                      {...register('categories')}
                    />
                    <span className="checkcad-label">
                      <span className="heading-4">Blue</span>
                    </span>
                  </label>
                </div>

                <div className="col-6 col-sm-3">
                  <label className="checkcad checked-yellow">
                    <input
                      type="checkbox"
                      className="checkcad-input"
                      value="Yellow"
                      {...register('categories')}
                    />
                    <span className="checkcad-label">
                      <span className="heading-4">Yellow</span>
                    </span>
                  </label>
                </div>
                <div className="col-6 col-sm-3">
                  <label className="checkcad checked-red">
                    <input
                      type="checkbox"
                      className="checkcad-input"
                      value="Red"
                      {...register('categories')}

                    />
                    <span className="checkcad-label">
                      <span className="heading-4">Red</span>
                    </span>
                  </label>
                </div>
                <div className="col-6 col-sm-3">
                  <label className="checkcad checked-secondary">
                    <input
                      type="checkbox"
                      className="checkcad-input"
                      value="Event"
                      {...register('categories')}
                    />
                    <span className="checkcad-label">
                      <span className="heading-4">Event</span>
                    </span>
                  </label>
                </div>
              </div>
            </section>

            <hr />

            <section>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h2 className="heading-3 text-uppercase m-0 heading-42">Unit</h2>
                <CheckAndUncheck
                  className={`button ${(labelCheckUnit === 'Check all') ? 'button-yellow' : 'button-gray'}`}
                  onClick={handleCheckAll}
                  type="button"
                >
                  {labelCheckUnit}
                </CheckAndUncheck>

              </div>

              <div className="row">
                <div className="col-6 col-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      value="Unit 1"
                      {...register('units')}
                    />
                    <span className="checkbox-label">UNIT 1</span>
                  </label>
                </div>
                <div className="col-6 col-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      value="Unit 2"
                      {...register('units')}
                    />
                    <span className="checkbox-label">UNIT 2</span>
                  </label>
                </div>
                <div className="col-6 col-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      value="Unit 3"
                      {...register('units')}
                    />
                    <span className="checkbox-label">UNIT 3</span>
                  </label>
                </div>
                <div className="col-6 col-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      value="Unit 4"
                      {...register('units')}
                    />
                    <span className="checkbox-label">UNIT 4</span>
                  </label>
                </div>
                <div className="col-6 col-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      value="Unit 5"
                      {...register('units')}
                    />
                    <span className="checkbox-label">UNIT 5</span>
                  </label>
                </div>
                <div className="col-6 col-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      value="Unit 6"
                      {...register('units')}
                    />
                    <span className="checkbox-label">UNIT 6</span>
                  </label>
                </div>
                <div className="col-6 col-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      value="Unit 7"
                      {...register('units')}
                    />
                    <span className="checkbox-label">UNIT 7</span>
                  </label>
                </div>
                <div className="col-6 col-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      value="Unit 8"
                      {...register('units')}
                    />
                    <span className="checkbox-label">UNIT 8</span>
                  </label>
                </div>
              </div>
            </section>

            <hr />

            <section>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h2 className="heading-3 text-uppercase m-0 heading-42">Games</h2>
                <CheckAndUncheck className={`button ${(labelCheckGames === 'Check all') ? 'button-yellow' : 'button-gray'}`} onClick={checkAllGames} type="button">
                  {labelCheckGames}

                </CheckAndUncheck>
              </div>

              <div className="row">

                <div className="col-12 col-sm-4 col-lg-4">


                  <ReactTooltip id={'quiz'}
                    clickable={true} place="right"

                    getContent={function () {
                      return (
                        <div className='tooltip-custom' style={unit.hiddenobject.length === 0 ? { maxWidth: "340px" } : { width: '150px' }} >
                          {unit.hiddenobject.length === 0 && <span>Sorry, no units available.</span>}
                          {unit.quiz.map((x, i) =>
                            <span key={i}> - {x}  <br /></span>
                          )}
                        </div>)
                    }} />

                  <label className="checkcad" data-place="right" data-tip={'dummystring'} data-background-color="transparent" data-for={'quiz'}>
                    <input
                      type="checkbox"
                      disabled={unit.hiddenobject.length === 0}
                      className="checkcad-input"
                      value="quiz"
                      {...register('games')}
                    />


                    <span className="checkcad-label">
                      <div className="media">
                        <div className="media-picture">
                          <img src='/assets/static/quiz.png' className="image" />
                        </div>
                        <div className="media-content">
                          <span className="heading-4">Quiz</span>
                        </div>
                      </div>
                    </span>
                  </label>
                </div>
                <div className="col-12 col-sm-4 col-lg-4">



                  <ReactTooltip id={'misterybox'}
                    clickable={true} place="right"
                    effect="float"
                    getContent={function () {
                      return (
                        <div className='tooltip-custom' style={unit.hiddenobject.length === 0 ? { maxWidth: "340px" } : { width: '150px' }} >
                          {unit.hiddenobject.length === 0 && <span>Sorry, no units available.</span>}
                          {unit.misterybox.map((x, i) =>
                            <span key={i}> - {x}  <br /></span>
                          )}
                        </div>)
                    }} />

                  <label className="checkcad" data-place="right" data-tip={'dummystring'} data-background-color="transparent" data-for={'misterybox'}>
                    <input
                      type="checkbox"
                      disabled={unit.hiddenobject.length === 0}
                      className="checkcad-input"
                      value="hidden_object"
                      {...register('games')}
                    />

                    <span className="checkcad-label">
                      <div className="media">
                        <div className="media-picture">
                          <img src='/assets/static/missing_object.png' className="image" />
                        </div>
                        <div className="media-content">
                          <span className="heading-4">Missing Object</span>
                        </div>
                      </div>
                    </span>
                  </label>
                </div>

                <ReactTooltip id={'hiddenobject'}
                  clickable={true} place="right"

                  getContent={function () {
                    return (
                      <div className='tooltip-custom' style={unit.hiddenobject.length === 0 ? { maxWidth: "340px" } : { width: '150px' }} >
                        {unit.hiddenobject.length === 0 && <span>Sorry, no units available.</span>}
                        {unit.hiddenobject.map((x, i) =>
                          <span key={i}> - {x}  <br /></span>
                        )}
                      </div>)
                  }} />

                <div className="col-12 col-sm-4 col-lg-4">
                  <label className="checkcad" data-place="right" data-tip={'dummystring'} data-background-color="transparent" data-for={'hiddenobject'}>
                    <input
                      disabled={unit.hiddenobject.length === 0}
                      type="checkbox"
                      className="checkcad-input"
                      value="mistery_box"
                      {...register('games')}
                    />
                    <span className="checkcad-label">
                      <div className="media">
                        <div className="media-picture">
                          <img src='/assets/static/magic_box.png' className="image" />
                        </div>
                        <div className="media-content">
                          <span className="heading-4">Magic Box</span>
                        </div>
                      </div>
                    </span>
                  </label>
                </div>
              </div>
            </section>

            <hr />

            <section>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h2 className="heading-3 text-uppercase m-0 heading-42">Groups</h2>

              </div>

              <div className="row mb-3">
                <div className="col-6 col-sm-3">
                  <RobotCard className="checkcad">
                    <input
                      type="checkbox"
                      className="checkcad-input-robot"
                      value="green"
                      {...register('groups')}
                    />
                    <span className="checkcad-label-robot">

                      <img src='/assets/static/robot/robot1.png' />
                      <input
                        type="text"
                        {...register('TeamGreenName')}
                        className="field field-robot  w-100"
                        placeholder="Green Team"

                      />
                    </span>
                  </RobotCard>
                </div>
                <div className="col-6 col-sm-3">
                  <RobotCard className="checkcad">
                    <input
                      type="checkbox"
                      className="checkcad-input-robot"
                      value="blue"

                      {...register('groups')}

                    />

                    <span className="checkcad-label-robot">
                      <img src='/assets/static/robot/robot2.png' />
                      <input
                        type="text"
                        className="field field-robot  w-100"
                        placeholder="Blue Team"
                        {...register('TeamBlueName')}

                      />
                    </span>
                  </RobotCard>
                </div>
                <div className="col-6 col-sm-3">
                  <RobotCard className="checkcad">
                    <input
                      type="checkbox"
                      className="checkcad-input-robot"
                      value="yellow"

                      {...register('groups')}
                    />

                    <span className="checkcad-label-robot">
                      <img src='/assets/static/robot/robot3.png' />
                      <input
                        type="text"
                        className="field field-robot  w-100"
                        placeholder="Yellow Team"
                        {...register('TeamOrangeName')}

                      />
                    </span>
                  </RobotCard>
                </div>
                <div className="col-6 col-sm-3">
                  <RobotCard >
                    <input
                      type="checkbox"
                      className="checkcad-input-robot"
                      value="red"

                      {...register('groups')}
                    />
                    <span className="checkcad-label-robot text-center">
                      <img src='/assets/static/robot/robot4.png' />
                      <input
                        type="text"
                        className="field field-robot w-100"
                        placeholder="Red Team"
                        {...register('TeamRedName')}

                      />
                    </span>
                  </RobotCard>
                </div>
              </div>
            </section>

            <button
              className="button button-large button-block button-blue hide show-md"
              type="button"
              onClick={handleSocketConnection}
              disabled={!validForm || labelCreateRoom !== "Create room"}
            >
              {labelCreateRoom}
            </button>
          </View>

          <footer className="footer show hide-md">
            <div className="container-fluid">
              <button onClick={handleSocketConnection} className="button button-large button-blue" type="button" disabled={!validForm || labelCreateRoom !== "Create room"}>
                {labelCreateRoom}
              </button>
            </div>
          </footer>


        </Container>
      </div>
      <div style={{ width: "15%" }} ></div>
    </div>
  )
}
