import styled from 'styled-components'

import bg from '../../../static/bg.png'

export const Container = styled.form`
width: 100%;
height: 100%;
display: flex;
align-items: center;
  .image {
    width: 100%;
    max-width: 100px;
    @media (max-width: 768px) {
      max-width: 75px;
    }
  }
  .media-content {
    flex: 1;
  }
  @media (min-width: 576px) {
    .media {
      height: 100%;
    }
    .no-sm-gutters {
      gap: 2rem;
      align-items: flex-end;
    }
  }
`

export const PrivateRoom = styled.label`
  color: #ffffff;
  background-color: #6d6d6d;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  @media (min-width: 768px) {
    margin: 0;
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const CheckAndUncheck = styled.button`
  width: 100%;
  max-width: 180px;
`

export const RobotCard = styled.label`
  
  background-position: center;  

  cursor: default;
  /* &.carton-green {
    background-color: #27d323;
  }
  &.carton-blue {
    background-color: #1dacf5;
  }
  &.carton-yellow {
    background-color: #ffcb00;
  }
  &.carton-red {
    background-color: #ff4360;
  } */
  img {
    width: 88%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
  }
  .field {
    text-align: center;
    margin-top: 100%;
    position: relative;
    z-index: 1;
  }
  .carton-content {
    padding: 10px;
  }
`
