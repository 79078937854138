import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import View from '../../components/View'
import { useSockets } from '../../context/socket.context';
import { Title } from '../View/styles';
import { Container } from "./style";
import { Howl } from 'howler'

export default function Config() {
    const location = useLocation();
    const brilhoFunc = (e: any) => {
        const val = e.target.value;
        console.log(val)
        const styleElem = document.head.appendChild(document.createElement("style"));
        sessionStorage.setItem("brilho", val);
        styleElem.innerHTML = `body:after {
            content: "";
            position: fixed;
            top: 0; bottom: 0; left: 0; right: 0; 
            background: rgba(0, 0, 0, ${val}%);
            pointer-events: none;
            z-index: 1;
          }`;

    };

  

    const [sound, setSound ] = useState<Howl>(new Howl({
        src: ['/assets/music/abertura.mp3'],
        autoplay: false,
        loop: true,
        volume:  sessionStorage.getItem("musica") ? Number(sessionStorage.getItem("musica")?.toString()) / 100 : 1
    }));

    useEffect(() => {
        sound.play();
        const script = document.createElement("script");
        script.src = "./assets/js/changeSound.js";
        script.async = true;
        document.body.appendChild(script);        
        return function limpar () { 
            document.body.removeChild(script);
            sound.stop();
          };
      }, [])
    
    const musica = (e: any) => {
        const val = e.target.value;
        sessionStorage.setItem("musica", val);
        sound.volume(Number(val) / 100);

    }


    const som = (e: any) => {
        const val = e.target.value;
        sessionStorage.setItem("som", val);
    }

    const router = useHistory()
    return (
        <div className="div-main">
            <Container >
                <div className='text-center' style={{ width: "15%", justifyItems: "start", justifyContent: "start" }}>
                    <img src="/assets/images/buttons/back.png" alt="" onClick={router.goBack} className='btn-leave' style={{ marginTop: "-65vh" }} />
                </div>
                <div style={{ width: "70%" }}>
                    <View>
                        <Title className="heading-3">OPTIONS</Title>

                        <div className="control-config">
                            <div className='div-config row'>
                                <div className="col-4">
                                    <img src="/assets/images/config/brilho.png" alt="" /> <span> Brightness </span>
                                </div>
                                <div className="col-8">
                                    <input

                                        defaultValue={sessionStorage.getItem("brilho") ? sessionStorage.getItem("brilho")?.toString() : 0}
                                        onChange={brilhoFunc}
                                        style={{  transform: "scaleX(-1)"}}
                                        type="range"
                                        min="0" max="90"
                                        className='input-range'
                                        step="1" />
                                </div>
                            </div>
                            <div className='div-config row'>
                                <div className="col-4">
                                    <img src="/assets/images/config/musica.png" alt="" /> <span> Music </span>
                                </div>
                                <div className="col-8">
                                    <input
                                        defaultValue={sessionStorage.getItem("musica") ? sessionStorage.getItem("musica")?.toString() : 100}
                                        onChange={musica}
                                        type="range"
                                        min="0" max="100"
                                        className='input-range'
                                        step="1" />
                                </div>
                            </div>
                            <div className='div-config row'>
                                <div className="col-4">
                                    <img src="/assets/images/config/som.png" alt="" /> <span> Sound </span>
                                </div>
                                <div className="col-8">
                                    <input
                                        id="changeSound"
                                        defaultValue={sessionStorage.getItem("som") ? sessionStorage.getItem("som")?.toString() : 100}
                                        onChange={som}
                                        type="range"
                                        min="0" max="100"
                                        className='input-range'
                                        step="1" />
                                </div>
                            </div>
                        </div>

                    </View>


                </div>
                <div className='text-center' style={{ width: "15%", justifyItems: "start", justifyContent: "start" }}>
                    <img src="/assets/images/buttons/estrela.png" alt="" onClick={() => router.replace("/credits")} className='btn-leave' style={{ marginTop: "-65vh" }} />
                </div>
            </Container>
        </div>
    )
}


