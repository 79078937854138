import styled from 'styled-components'

// AQUI
export const Container = styled.div`
  height: 75vh !important;
  border: 7px solid #A054A0 !important;
  border-radius: 32px !important;
  padding: 32px ;
  
  /* @media (max-width: 768px) and (orientation: landscape) {
    height: 100%;
    border-width: 4px !important;
    border-radius: 10px !important;
    padding: 0.5rem !important;
  } */
  /* @media (max-width: 576px) { */
  @media (max-height: 768px) {
    height: 85%;
  }
  @media (max-width: 768px) {
    height: 100%;
    border-width: 4px !important;
    border-radius: 15px !important;
    padding: 6px !important;
  }
  @media (max-height: 420px) {
    padding: 5px;
    height: 80vh !important;
  }
  &.is-scroll > div {
    border-radius: 10px !important;
  }
`
export const Title = styled.h1`
  font-size: 48px;
  padding: 30px;
  font-family: 'Earths Mightiest Punch' !important;
  text-align: center !important;
  line-height: 0.8 !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  &:after {
    content: '';
    background-color: #b7b7b7;
    width: auto;
    height: 3px;
    border-radius: 5rem;
    margin: 1.5rem 3rem 0;
    @media (max-width: 768px) {
      margin: 1rem 0;
    }
    @media  (max-height: 420px) {
      margin: 1rem 0;
    }
  }
`
