import styled from 'styled-components'


export const Container = styled.form`
width: 100%;
height: 100%;
display: flex;
align-items: center;
  .image {
    width: 100%;
    max-width: 100px;
    @media (max-width: 768px) {
      max-width: 75px;
    }
  }
  .media-content {
    flex: 1;
  }
  @media (min-width: 576px) {
    .media {
      height: 100%;
    }
    .no-sm-gutters {
      gap: 2rem;
      align-items: flex-end;
    }
  }
`
